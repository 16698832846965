<template>
  <node-view-wrapper class="feedback-node">
    <div v-if="show" class="feedback-container">
      <div class="title">{{ title }}</div>
      <div v-if="ratingType !== types.NONE" class="feedback-options">
        <FeedbackControls
          :value="selectedRating"
          :type="ratingType"
          :results="results"
          :disabled="ratingTimedOut"
          @input="submitRating"
        />
      </div>
      <template v-if="allowComments">
        <div class="subtitle">{{ subtitle }}</div>
        <div class="comment-container">
          <TipTap
            editor-height="100"
            class="w-100 flex-1"
            :content.sync="commentText"
            :character-limit="maxCommentLength"
            :editable="!commentTimedOut"
            hide-toolbar
            @update:content="commentHasEdit = true"
            @ctrl-enter="submitComment"
          />
          <div v-if="commentLength > maxCommentLength - 100" class="length-indicator">
            {{ commentLength }}/{{ maxCommentLength }}
          </div>
        </div>
        <b-button
          :disabled="!cleanContent || !commentHasEdit || commentTimedOut"
          variant="primary"
          class="btn-block"
          @click="submitComment"
        >
          {{ commentTimedOut ? 'Feedback Sent' : 'Send Feedback' }}
        </b-button>
      </template>
    </div>
  </node-view-wrapper>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import FeedbackControls from './FeedbackControls.vue'

import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { FeedbackItem, FeedbackRatingTypes, Path } from '@/types'
import { useFeedbackStore } from '@/store/public/feedback'
import { useFeaturesStore } from '@/store/common/features'
import { createFeedbackConfig, CUSTOM_STORY_LEVEL } from '@/constants'

const components = {
  NodeViewWrapper,
  FeedbackControls,
}

// eslint-disable-next-line no-use-before-define
@Component<FeedbackTipTapView>({
  components,
  watch: {
    storyPath() {
      this.reset()
    },
    currentFeedbackItem: {
      handler(item: FeedbackItem) {
        this.selectedRating = item?.rating || this.selectedRating || null
        if (!this.commentHasEdit) {
          this.commentText = item?.comment || this.commentText || ''
        }
      },
      deep: true,
      immediate: true,
    },
  },
})
export default class FeedbackTipTapView extends Vue.extend({ props: nodeViewProps }) {
  feedbackStore = useFeedbackStore()
  featuresStore = useFeaturesStore()
  commentText: string = ''
  storyPath: Path = Path.root
  selectedRating: number | null = null
  commentHasEdit = false
  ratingTimedOut = false
  commentTimedOut = false
  types = FeedbackRatingTypes
  maxCommentLength = 500
  timeouts: any[] = []

  reset() {
    this.commentHasEdit = false
    this.commentText = ''
    this.selectedRating = null
  }

  get appRank() {
    return this.$typedStore.activeVisualisation.appRank
  }

  get appId() {
    return this.$typedStore.activeVisualisation.appId
  }

  get feedbackQuestionId() {
    return this.config.id
  }

  get storyPathString() {
    return this.storyPath.toString()
  }

  get feedbackRecord() {
    const appFeedback = this.feedbackStore.feedback[this.appId]
    const questionFeedback = appFeedback ? appFeedback[this.feedbackQuestionId] : undefined
    return questionFeedback ? questionFeedback[this.storyPathString] : undefined
  }

  get currentFeedbackItem() {
    return this.feedbackRecord?.feedbackItem
  }

  get results() {
    return this.feedbackRecord?.ratingResults ?? []
  }

  get show() {
    return (
      this.config.id &&
      (this.config.level === this.storyPath.level || this.config.level === CUSTOM_STORY_LEVEL)
    )
  }

  get config() {
    return (
      this.$typedStore.activeVisualisation.visualisationStore.app.config.feedbackQuestions.find(
        (q) => q.id === this.node.attrs.questionId,
      ) ?? createFeedbackConfig('')
    )
  }

  get commentLength() {
    const tiptap: any = this.$children
      .map((c: any) => c.$children.map((c2: any) => c2.$children))
      .flat(Infinity)
      .find((c: any) => c.editor)
    return tiptap ? tiptap.editor.storage.characterCount.characters() : this.cleanContent.length
  }

  get cleanContent() {
    return this.commentText.replace(/<\/?p>/g, '')
  }

  get title() {
    return this.config.title
  }

  get subtitle() {
    return this.config.subtitle
  }

  get allowComments() {
    return this.config.allowComments
  }

  get ratingType() {
    return this.config.ratingType
  }

  mounted() {
    this.storyPath = this.$typedStore.activeVisualisation.storyPath
  }

  beforeDestroy() {
    if (this.feedbackStore.isKiosk) {
      for (const timeout of this.timeouts) {
        clearTimeout(timeout)
      }
      this.feedbackStore.clearRatingState(this.appId, this.feedbackQuestionId, this.storyPathString)
      this.feedbackStore.clearCommentState(
        this.appId,
        this.feedbackQuestionId,
        this.storyPathString,
      )
    }
  }

  async submitRating(value: number) {
    if (this.feedbackStore.isKiosk) {
      if (this.ratingTimedOut) {
        return
      }
      this.ratingTimedOut = true
      this.timeouts.push(
        setTimeout(() => {
          this.feedbackStore.clearRatingState(
            this.appId,
            this.feedbackQuestionId,
            this.storyPathString,
          )
          this.selectedRating = null
          this.ratingTimedOut = false
        }, this.featuresStore.feedbackKioskUserWaitDuration),
      )
    }
    this.selectedRating = value
    await this.feedbackStore.addOrUpdateRating(
      this.appRank,
      this.appId,
      this.feedbackQuestionId,
      this.storyPathString,
      value,
    )
  }

  async submitComment() {
    if (!this.commentHasEdit) return
    this.commentHasEdit = false

    if (this.feedbackStore.isKiosk) {
      if (this.commentTimedOut) {
        return
      }
      this.commentTimedOut = true
      this.timeouts.push(
        setTimeout(() => {
          this.feedbackStore.clearCommentState(
            this.appId,
            this.feedbackQuestionId,
            this.storyPathString,
          )
          this.commentText = ''
          this.commentHasEdit = false
          this.commentTimedOut = false
        }, this.featuresStore.feedbackKioskUserWaitDuration),
      )
    }
    await this.feedbackStore.addOrUpdateComment(
      this.appRank,
      this.appId,
      this.config!.id,
      this.storyPathString,
      this.cleanContent,
    )
  }
}
</script>

<style scoped lang="scss">
.feedback-node {
  width: 100%;
}
.feedback-container {
  background: white;
  border-radius: var(--border-radius);
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  padding: 16px;
  margin: 24px 0;
  .feedback-options {
    width: 100%;
  }
  .title {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: #455;
  }
  .comment-container {
    width: 100%;
    display: flex;
    color: black;
    position: relative;
    :deep(.ProseMirror) {
      caret-color: black;
      color: black;
      padding-right: 32px;
    }
    .length-indicator {
      position: absolute;
      right: 40px;
      bottom: 6px;
      font-size: 12px;
      font-weight: 700;
      color: #888;
    }
    .send-button {
      position: absolute;
      right: 12px;
      bottom: 12px;
      color: #aaa;
      width: 24px;
      height: 24px;
      &.active {
        cursor: pointer;
        color: #555;
        &:hover {
          color: black;
        }
      }
    }
  }
  .subtitle {
    width: 100%;
    font-weight: 400;
    font-size: 15px;
  }
  .total {
    margin-bottom: 1em;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
