import {
  Bubble,
  BubbleCircle,
  bubblesShapesService,
  RenderedBubbleGroup,
  RenderedBubbleHalo,
  RenderedBubblePulse,
} from '@/components/public/Visualisations/Bubbles'
import {
  defaultVisPositions,
  UI_EVENTS,
  VISUALISATION_VIEWBOX_POSITION_OFFSET,
  VISUALISATION_VIEWBOX_SIZE,
} from '@/constants'
import { OrbVizAnalytics } from '@/plugins/analytics'
import typedStore from '@/store/typedStore'
import {
  BubblesPopupDetails,
  ComparisonEqualityOperators,
  Coordinate2D,
  CustomBubblesCompareCategory,
  CustomBubblesFilter,
  VisPositions,
  VisNode,
} from '@/types'
import Konva from 'konva'

const FADE_DURATION_SECONDS = 0.2

export type ClustersHash = number

export function getBubblesCanvas(): HTMLElement | null {
  return document.querySelector('#bubblesCanvas')
}

export function fadeInBubbleGroup(group: RenderedBubbleGroup): void {
  fadeInCircle(group.bubbleCircle)
  fadeInCircle(group.haloCircle)
  fadeInCircle(group.pulseCircle)
}

export function generateHashOfPaths(path: string[]): ClustersHash {
  const input = path.join('')
  let hash = 5381
  let i = input.length
  while(i) {
    hash = (hash * 33) ^ input.charCodeAt(--i);
  }
  return hash >>> 0;
}

function fadeInCircle(circle: Konva.Circle): void {
  // We cannot easily grow the circle in the same way as we shrink the circle
  // while fading out as the circle radius can change while the animation is
  // running and this change is not reflected when the animation completes.
  circle.opacity(0)
  circle.to({
    opacity: 1,
    easing: Konva.Easings.EaseInOut,
    duration: FADE_DURATION_SECONDS,
  })
}

export function fadeOutBubbleGroup(group: RenderedBubbleGroup): void {
  fadeOutCircle(group.bubbleCircle)
  fadeOutCircle(group.haloCircle)
  fadeOutCircle(group.pulseCircle)
}

function fadeOutCircle(circle: Konva.Circle): void {
  circle.to({
    opacity: 0,
    radius: 0,
    easing: Konva.Easings.EaseInOut,
    duration: FADE_DURATION_SECONDS,
    onFinish: () => {
      circle.destroy()
    },
  })
}

export function updateBubbleGroupProperties(
  group: RenderedBubbleGroup,
  pulseAnimations: Record<string, Konva.Animation> = {},
  bubblesVisualisationScaleFactor: number,
) {
  const { bubbleCircle } = group
  const { renderedBubble } = bubbleCircle
  updateBubbleCircleProperties(bubbleCircle, bubblesVisualisationScaleFactor)
  updateBubbleHaloCircleProperties(
    group.haloCircle,
    renderedBubble.halo,
    bubblesVisualisationScaleFactor,
  )
  updateBubblePulseCircleProperties(
    group.pulseCircle,
    renderedBubble.pulse,
    pulseAnimations,
    bubblesVisualisationScaleFactor,
  )
}

export function updateBubbleCircleProperties(
  bubbleCircle: BubbleCircle,
  bubblesVisualisationScaleFactor: number,
) {
  // The rendered radius needs to match the radius used by the simulation.
  // Because half of the circle's stroke is rendered inside the circle and
  // the other half is rendered outside the circle we need to accommodate this
  // and remove half of the stroke (borderWidth) from the circle radius value.
  const { renderedBubble } = bubbleCircle
  const circleStrokeWidth = renderedBubble.strokeWidth * bubblesVisualisationScaleFactor
  const circleRadius =
    renderedBubble.radius * bubblesVisualisationScaleFactor - circleStrokeWidth / 2

  bubbleCircle.radius(circleRadius)
  bubbleCircle.strokeWidth(circleStrokeWidth)
  bubbleCircle.stroke(renderedBubble.strokeColor)
  bubbleCircle.fill(renderedBubble.fill)
}

function updateBubbleHaloCircleProperties(
  circle: Konva.Circle,
  halo: RenderedBubbleHalo,
  bubblesVisualisationScaleFactor: number,
) {
  if (halo.show) {
    const circleStrokeWidth = halo.strokeWidth * bubblesVisualisationScaleFactor
    const circleRadius = halo.radius * bubblesVisualisationScaleFactor

    circle.visible(true)
    circle.radius(circleRadius)
    circle.strokeWidth(circleStrokeWidth)
    circle.stroke(halo.strokeColor)
  } else {
    circle.visible(false)
  }
}

function updateBubblePulseCircleProperties(
  circle: Konva.Circle,
  pulse: RenderedBubblePulse,
  pulseAnimations: Record<string, Konva.Animation> = {},
  bubblesVisualisationScaleFactor: number,
) {
  // stop the existing animation if one already exists as we will
  // create a new one with any new pulse properties
  const animation = pulseAnimations[circle.id()]
  if (animation) {
    animation.stop()
    delete pulseAnimations[circle.id()]
  }

  if (pulse.show) {
    const circleRadius = pulse.radius * bubblesVisualisationScaleFactor

    circle.visible(true)
    circle.radius(circleRadius)

    const newAnimation = bubblesShapesService.buildBubblePulseAnimation(pulse, circle)
    pulseAnimations[circle.id()] = newAnimation
    newAnimation.start()
  } else {
    circle.visible(false)
  }
}

export function handleBubbleTap(bubbleCircle: BubbleCircle, analytics: OrbVizAnalytics) {
  typedStore.activeVisualisation.updateStoryPath(bubbleCircle.path)
  analytics.event(UI_EVENTS.Click, {
    event_category: 'bubbles',
    event_label: bubbleCircle.path,
  })
}

export function showPopup(bubbleCircle: BubbleCircle) {
  setCanvasCursorStyle('pointer')
  const { renderedBubble, node } = bubbleCircle
  renderedBubble.isHighlighted = !renderedBubble.isHighlighted
  updateBubbleCircleProperties(bubbleCircle, bubblesVisualisationScaleFactor())

  typedStore.activeVisualisation.setActiveBubblesPopupDetails({
    node,
    position: transformCoordinatesBubblesToScreen(
      renderedBubble.currentPosition,
      bubblesVisualisationScaleFactor(),
      bubblesMinSizeSquareOffset(),
    ),
    radius:
      (renderedBubble.radius + renderedBubble.halo.totalWidth) * bubblesVisualisationScaleFactor(),
  } as BubblesPopupDetails)
}

export function hidePopup() {
  const path = typedStore.activeVisualisation.activeBubblesPopupDetails?.node?.path
  if (path) {
    const { bubbleCircle } = bubblesShapesService.getBubbleGroup(path)
    const { renderedBubble } = bubbleCircle
    setCanvasCursorStyle('default')
    renderedBubble.isHighlighted = !renderedBubble.isHighlighted
    updateBubbleCircleProperties(bubbleCircle, bubblesVisualisationScaleFactor())
  }
  typedStore.activeVisualisation.setActiveBubblesPopupDetails(undefined)
}

function setCanvasCursorStyle(cursorStyle: string) {
  const canvas = getBubblesCanvas()
  if (canvas) {
    canvas.style.cursor = cursorStyle
  }
}

export function shouldBubblesAmalgamate(bubbles: Bubble[]) {
  // If a bubble is within 5% of its destination then it is considered near its destination
  const nearDestinationThreshold = 0.1
  let count = 0

  for (const bubble of bubbles) {
    const distanceX = bubble.destinationPosition.x - bubble.currentPosition.x
    const distanceY = bubble.destinationPosition.y - bubble.currentPosition.y
    const distanceTotal = Math.sqrt(distanceX ** 2 + distanceY ** 2)
    const diagonalDistance = Math.sqrt(
      VISUALISATION_VIEWBOX_SIZE ** 2 + VISUALISATION_VIEWBOX_SIZE ** 2,
    )
    const distancePercentage = diagonalDistance === 0 ? 0 : distanceTotal / diagonalDistance

    if (distancePercentage <= nearDestinationThreshold) {
      count++
    }
  }

  // The bubbles should amalgamate if 20% or more of them are near their destination
  return count / bubbles.length >= 0.2
}

export function bubblesVisualisationScaleFactor() {
  return typedStore.public.size.bubblesVisualisationScaleFactor
}

export function bubblesMinSizeSquareOffset() {
  return typedStore.public.size.bubblesMinSizeSquareOffset
}

export function getVisPosition(
  path: string,
  positionsMap: Record<string, VisPositions>,
  level: number,
  hash: ClustersHash,
): Coordinate2D {
  const positions = positionsMap[path] ?? defaultVisPositions()
  return positions.clusterPathsOverrides?.[hash] ?? positions.levelOverrides?.[level] ?? positions.default
}

export function isBubbleCircle(shape: Konva.Shape | null | undefined): shape is BubbleCircle {
  return shape?.name() === BubbleCircle.shapeName
}

// We allow visualisationScaleFactor and minSizeSquareOffset to be passed in so cached values can be provided instead
// of accessing the store directly.
export function transformCoordinatesBubblesToScreen(
  coordinates: Coordinate2D,
  bubblesVisualisationScaleFactor: number,
  bubblesMinSizeSquareOffset: Coordinate2D,
) {
  return {
    x: transformCoordinateBubblesToScreen(
      coordinates.x,
      bubblesVisualisationScaleFactor,
      bubblesMinSizeSquareOffset.x,
    ),
    y: transformCoordinateBubblesToScreen(
      coordinates.y,
      bubblesVisualisationScaleFactor,
      bubblesMinSizeSquareOffset.y,
    ),
  }
}

function transformCoordinateBubblesToScreen(
  coordinate: number,
  bubblesVisualisationScaleFactor: number,
  minSizeSquareOffset: number,
) {
  return (
    (coordinate - VISUALISATION_VIEWBOX_POSITION_OFFSET) * bubblesVisualisationScaleFactor +
    minSizeSquareOffset
  )
}

export function transformCoordinatesScreenToBubbles(coordinates: Coordinate2D) {
  return {
    x: transformCoordinateScreenToBubbles(coordinates.x, bubblesMinSizeSquareOffset().x),
    y: transformCoordinateScreenToBubbles(coordinates.y, bubblesMinSizeSquareOffset().y),
  }
}

function transformCoordinateScreenToBubbles(coordinate: number, minSizeSquareOffset: number) {
  return (
    (coordinate - minSizeSquareOffset) / bubblesVisualisationScaleFactor() +
    VISUALISATION_VIEWBOX_POSITION_OFFSET
  )
}

export function checkHaloBubblesFilter(filter: CustomBubblesFilter, visNode: VisNode) {
  const { category, compare, operator, value } = filter
  const categoryData = visNode.data[category]
  const compareTo = compare === CustomBubblesCompareCategory ? visNode.data[value] : value
  switch (operator) {
    case ComparisonEqualityOperators.LESS_THAN:
      return Number(categoryData) < Number(compareTo)
    case ComparisonEqualityOperators.GREATER_THAN:
      return Number(categoryData) > Number(compareTo)
    case ComparisonEqualityOperators.LESS_THAN_OR_EQUAL:
      return Number(categoryData) <= Number(compareTo)
    case ComparisonEqualityOperators.GREATER_THAN_OR_EQUAL:
      return Number(categoryData) >= Number(compareTo)
    case ComparisonEqualityOperators.STRICT_EQUAL:
      if (isNaN(Number(categoryData)) && isNaN(Number(compareTo))) return categoryData === compareTo
      else return Number(categoryData) === Number(compareTo)
    case ComparisonEqualityOperators.STRICT_UNEQUAL:
      if (isNaN(Number(categoryData)) && isNaN(Number(compareTo))) return categoryData !== compareTo
      else return Number(categoryData) !== Number(compareTo)
    default:
      return false
  }
}
