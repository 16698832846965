import {
  BubbleConfig,
  BubbleHalo,
  BubblePulse,
  checkHaloBubblesFilter,
  RenderedBubble,
  RenderedBubbleHalo,
  RenderedBubblePulse,
  SimulationBubble,
} from '@/components/public/Visualisations/Bubbles'
import { makeDarker } from '@/helpers'
import typedStore from '@/store/typedStore'
import { Coordinate2D, HaloBubblesConfig, HollowBubbleDisplays, Path, VisNode } from '@/types'
import { hsl } from 'd3-color'

const DEFAULT_COLOR = 'red'
const DEFAULT_STROKE_COLOR = 'black'
const SELECTED_STROKE_COLOR = 'black'
const HIGHLIGHTED_STROKE_COLOR = 'black'
const HOLLOW_FILL_COLOR = 'rgba(0, 0, 0, 0)' // transparent

export class Bubble implements SimulationBubble, RenderedBubble {
  private _visNode: VisNode
  private _config: BubbleConfig
  private _activeStoryPath: Path
  private _halo: BubbleHalo
  private _pulse: BubblePulse
  private _onPositionChanged: (coordinates: Coordinate2D) => void = () => {}

  _x: number = 0
  _y: number = 0
  isHighlighted: boolean = false
  destinationPosition: Coordinate2D = { x: 0, y: 0 }

  constructor(visNode: VisNode, config: BubbleConfig, activeStoryPath: Path) {
    this._visNode = visNode
    this._config = config
    this._activeStoryPath = activeStoryPath
    this._halo = new BubbleHalo(this)
    this._pulse = new BubblePulse(this)
    this.updateConfig(config)
  }

  get path() {
    return this._visNode.path
  }

  get x(): number {
    return this._x
  }

  set x(x: number) {
    this._x = x
    this._onPositionChanged({ x, y: this.y })
  }

  get y(): number {
    return this._y
  }

  set y(y: number) {
    this._y = y
    this._onPositionChanged({ x: this.x, y })
  }

  get currentPosition(): Coordinate2D {
    return { x: this._x, y: this._y }
  }

  get level1(): string {
    return this._visNode.path.level1
  }

  // The total Bubble radius used by the simulation.
  // This includes the Bubble radius, the total halo width
  // and the global bubble spacing setting.
  get displacementRadius(): number {
    if (this.radius === 0) {
      return 0
    }

    return this.radius + this._halo.totalWidth + this._config.bubbleParams.spacing
  }

  get totalValue(): number {
    return this._visNode.total
  }

  get radius(): number {
    const globalRadiusScale = typedStore.visualisationData.bubbleRadiusScale
    const dataValueRadius = Math.abs(globalRadiusScale(Math.abs(this._visNode.scaledTotal)))
    // The Bubble's minimum size is the configured border width
    const minimumRadius = this.strokeWidth
    return Math.max(minimumRadius, dataValueRadius)
  }

  get halo(): RenderedBubbleHalo {
    return this._halo
  }

  get pulse(): RenderedBubblePulse {
    return this._pulse
  }

  get fill() {
    const { display, categoryField, categoryValue } = this._config.hollowBubbles
    switch (display) {
      case HollowBubbleDisplays.NEGATIVE:
        if (this._visNode.total < 0) return HOLLOW_FILL_COLOR
        break
      case HollowBubbleDisplays.POSITIVE:
        if (this._visNode.total > 0) return HOLLOW_FILL_COLOR
        break
      case HollowBubbleDisplays.CATEGORY:
        if (categoryField && this._visNode.data[categoryField] === categoryValue)
          return HOLLOW_FILL_COLOR
        break
      default:
        return this.color
    }
    return this.color
  }

  get strokeColor() {
    if (this.isSelected) {
      return SELECTED_STROKE_COLOR
    }
    if (this.isHighlighted) {
      return HIGHLIGHTED_STROKE_COLOR
    }
    return this.color ? makeDarker(this.color) : DEFAULT_STROKE_COLOR
  }

  get visNode() {
    return this._visNode
  }

  get strokeWidth() {
    return Number(this._config.hollowBubbles.borderWidth)
  }

  private get isSelected() {
    return this.path.equals(this._activeStoryPath)
  }

  private get filterLightness() {
    return this._config.filterLightness ?? 1
  }

  private get color() {
    if (this._visNode.isTranslucent) {
      // Records that have been filtered out
      const colour = hsl(this._visNode.colour || '')
      // Adjust lightness according to user setting
      colour.l = this.filterLightness
      return colour.rgb().toString()
    }
    return this._visNode.colour ?? DEFAULT_COLOR
  }

  onPositionChanged(callback: (coordinates: Coordinate2D) => void) {
    this._onPositionChanged = callback
  }

  updateNode(visNode: VisNode) {
    this._visNode = visNode
  }

  updateConfig(config: BubbleConfig) {
    this._config = config
    this._halo.setActiveConfig(this.findActiveHaloConfig(config, (c) => c.showHalo))
    this._pulse.setActiveConfig(this.findActiveHaloConfig(config, (c) => c.showPulse))
  }

  findActiveHaloConfig(config: BubbleConfig, filter: (c: HaloBubblesConfig) => boolean) {
    return config.haloBubbles.filter(filter).find((haloBubblesConfig: HaloBubblesConfig) => {
      const filterKey = haloBubblesConfig.filter
      if (filterKey && filterKey in config.customBubblesGroups) {
        const { filters } = config.customBubblesGroups[filterKey]
        for (const filter of filters) {
          if (checkHaloBubblesFilter(filter, this._visNode)) {
            return true
          }
        }
      }
      return false
    })
  }

  updateActiveStoryPath(activeStoryPath: Path) {
    this._activeStoryPath = activeStoryPath
  }
}
