import {
  NavButtonDisplayTypes,
  NavButtonKey,
  NavButtonKeys,
  NavButtonsConfig,
  VisualisationType,
  VisualisationTypes,
} from '@/types'

/*
 * These are the non-configurable details for each nav button
 */
export const NAV_BUTTON_DETAILS: Record<
  NavButtonKey,
  {
    name: string
    defaultLabel: string
    defaultIcon: string | string[]
    visualisationType?: VisualisationType
  }
> = {
  [NavButtonKeys.BUBBLES]: {
    name: 'Bubbles',
    defaultLabel: 'Bubbles',
    defaultIcon: ['custom', 'bubbles'],
    visualisationType: VisualisationTypes.BUBBLES,
  },
  [NavButtonKeys.DOWNLOAD]: {
    name: 'Download',
    defaultLabel: 'Data',
    defaultIcon: 'file-download',
  },
  [NavButtonKeys.FILTER]: {
    name: 'Filter by Category',
    defaultLabel: 'Filter',
    defaultIcon: 'filter',
  },
  [NavButtonKeys.INFO]: {
    name: 'Info',
    defaultLabel: 'Info',
    defaultIcon: 'question',
  },
  [NavButtonKeys.MAP]: {
    name: 'Map',
    defaultLabel: 'Map',
    defaultIcon: ['custom', 'map'],
    visualisationType: VisualisationTypes.MAP,
  },
  [NavButtonKeys.MENU]: {
    name: 'Menu',
    defaultLabel: 'Menu',
    defaultIcon: 'bars',
  },
  [NavButtonKeys.NESTED_BUBBLES]: {
    name: 'Nested Bubbles',
    defaultLabel: 'Nested',
    defaultIcon: ['custom', 'nested-bubbles'],
    visualisationType: VisualisationTypes.NESTED_BUBBLES,
  },
  [NavButtonKeys.SEARCH]: {
    name: 'Search',
    defaultLabel: 'Search',
    defaultIcon: 'search',
  },
  [NavButtonKeys.VISUALISATIONS]: {
    name: 'Visualisations',
    defaultLabel: 'View',
    defaultIcon: ['custom', 'visualisations'],
  },
  [NavButtonKeys.SUNBURST]: {
    name: 'Sunburst',
    defaultLabel: 'Sunburst',
    defaultIcon: ['custom', 'sunburst'],
    visualisationType: VisualisationTypes.SUNBURST,
  },
  [NavButtonKeys.XY_BUBBLES]: {
    name: 'XY',
    defaultLabel: 'XY',
    defaultIcon: ['custom', 'xy-bubbles'],
    visualisationType: VisualisationTypes.XY_BUBBLES,
  },
}

export const NAV_BUTTONS_CONFIG_KEY = 'navButtons'

/*
 * These are the default values for configurable nav button properties
 */
export const DEFAULT_NAV_BUTTONS_CONFIG: NavButtonsConfig = {
  [NavButtonKeys.MENU]: {
    key: NavButtonKeys.MENU,
    order: -1,
    displayType: NavButtonDisplayTypes.STATIC,
    label: NAV_BUTTON_DETAILS[NavButtonKeys.MENU].defaultLabel,
  },
  [NavButtonKeys.INFO]: {
    key: NavButtonKeys.INFO,
    order: 0,
    displayType: NavButtonDisplayTypes.STANDALONE,
    label: NAV_BUTTON_DETAILS[NavButtonKeys.INFO].defaultLabel,
  },
  [NavButtonKeys.VISUALISATIONS]: {
    key: NavButtonKeys.VISUALISATIONS,
    order: 1,
    displayType: NavButtonDisplayTypes.STATIC,
    label: NAV_BUTTON_DETAILS[NavButtonKeys.VISUALISATIONS].defaultLabel,
  },
  [NavButtonKeys.SEARCH]: {
    key: NavButtonKeys.SEARCH,
    order: 2,
    displayType: NavButtonDisplayTypes.STANDALONE,
    label: NAV_BUTTON_DETAILS[NavButtonKeys.SEARCH].defaultLabel,
  },
  [NavButtonKeys.DOWNLOAD]: {
    key: NavButtonKeys.DOWNLOAD,
    order: 3,
    displayType: NavButtonDisplayTypes.STANDALONE,
    label: NAV_BUTTON_DETAILS[NavButtonKeys.DOWNLOAD].defaultLabel,
  },
  [NavButtonKeys.FILTER]: {
    key: NavButtonKeys.FILTER,
    order: 4,
    displayType: NavButtonDisplayTypes.STANDALONE,
    label: NAV_BUTTON_DETAILS[NavButtonKeys.FILTER].defaultLabel,
  },
  [NavButtonKeys.BUBBLES]: {
    key: NavButtonKeys.BUBBLES,
    order: 0,
    displayType: NavButtonDisplayTypes.VISUALISATION,
    label: NAV_BUTTON_DETAILS[NavButtonKeys.BUBBLES].defaultLabel,
  },
  [NavButtonKeys.MAP]: {
    key: NavButtonKeys.MAP,
    order: 1,
    displayType: NavButtonDisplayTypes.VISUALISATION,
    label: NAV_BUTTON_DETAILS[NavButtonKeys.MAP].defaultLabel,
  },
  [NavButtonKeys.NESTED_BUBBLES]: {
    key: NavButtonKeys.NESTED_BUBBLES,
    order: 2,
    displayType: NavButtonDisplayTypes.VISUALISATION,
    label: NAV_BUTTON_DETAILS[NavButtonKeys.NESTED_BUBBLES].defaultLabel,
  },
  [NavButtonKeys.SUNBURST]: {
    key: NavButtonKeys.SUNBURST,
    order: 3,
    displayType: NavButtonDisplayTypes.VISUALISATION,
    label: NAV_BUTTON_DETAILS[NavButtonKeys.SUNBURST].defaultLabel,
  },
  [NavButtonKeys.XY_BUBBLES]: {
    key: NavButtonKeys.XY_BUBBLES,
    order: 4,
    displayType: NavButtonDisplayTypes.VISUALISATION,
    label: NAV_BUTTON_DETAILS[NavButtonKeys.XY_BUBBLES].defaultLabel,
  },
}
