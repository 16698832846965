import { getTree } from '@/api'
import {
  BubbleConfig,
  ClusterAndHeaderBox,
  ClusterAndHeaderBoxCollection,
  ClustersHash,
  generateHashOfPaths,
} from '@/components/public/Visualisations/Bubbles'
import { BubblesConfigV2 } from '@/components/public/Visualisations/BubblesV2/types'
import { defaultVisPositions, DEFAULT_TAB_SCALE_FACTOR, PATH_ROOT, PATH_SUBROOT } from '@/constants'
import store from '@/store'
import { sortByPath } from '@/store/helpers'
import typedStore from '@/store/typedStore'
import {
  FieldTypes,
  FilterDisplayTypes,
  LeafNode,
  LevelField,
  NodePositionKey,
  NodePositionKeys,
  NodePositionsKey,
  NodePositionsKeys,
  NodeTypes,
  Path,
  TreeNode,
  VisNode,
  VisPositions,
  VisualisationTypes,
} from '@/types'
import { NodeGroup, NodeGroups, VisTreeNode } from '@/types/nodes'
import { scalePow } from 'd3-scale'
import _groupBy from 'lodash/groupBy'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface SumTotalsByUnitItem {
  type: typeof FieldTypes.MONETARY | typeof FieldTypes.NUMERIC | typeof FieldTypes.CALCULATION
  unit: string
  total: number
}

export interface IVisualisationDataState {
  treeRoot: TreeNode
  dataLoaded: Boolean
}

type Level1Config = Record<
  string,
  {
    scaleFactor: number
    colour: string | null
  }
>

@Module({ dynamic: true, store, name: 'VisualisationData', namespaced: true })
export default class VisualisationData extends VuexModule implements IVisualisationDataState {
  treeRoot: TreeNode = new TreeNode({ name: '', path: '' })
  dataLoaded = false

  @Mutation
  setTreeRoot(payload: TreeNode) {
    this.treeRoot = payload
  }

  get levelCount() {
    return this.levels.length
  }

  get allLevels() {
    return Array(this.levelCount)
      .fill(null)
      .map((_, index) => index + 1)
  }

  get sortedRootChildren() {
    return this.childrenByPath(Path.root)
      .slice(0)
      .sort((a, b) =>
        sortByPath(a, b, typedStore.activeVisualisation.visualisationStore.entities.nodes.order),
      )
  }

  get childrenByPath() {
    return (path: Path) => {
      const node = this.treeRoot.search(path)
      return node && node.children ? node.children : []
    }
  }

  get leafData() {
    return (path: Path): LeafNode[] => {
      const node = this.treeRoot.search(path)
      return node ? this.getLeafData(node) : []
    }
  }

  get leafDataForRoot() {
    return this.leafData(Path.root)
  }

  get filterLeafData() {
    return (path: Path): LeafNode[] => {
      return this.leafData(path).filter(typedStore.public.search.leafNodeFilter)
    }
  }

  get filteredLeafDataByCurrentPath() {
    const path = typedStore.activeVisualisation.storyPath
    return this.filterLeafData(path)
  }

  get filteredLeafDataForRoot() {
    return this.filterLeafData(Path.root)
  }

  get fieldValueForColumn() {
    return (
      column: string,
      leafData: (LeafNode | VisNode)[] = this.filteredLeafDataByCurrentPath,
    ) => {
      const field = typedStore.visualisationApp.field(column)
      if (field) {
        if (field.type === FieldTypes.CALCULATION) {
          return typedStore.activeVisualisation.visualisationStore.entities.fieldCalculation.fieldCalculation(
            column,
            leafData,
          )
        } else if (field.type === FieldTypes.NUMERIC || field.type === FieldTypes.MONETARY) {
          return leafData.reduce(
            (sum: number, node: LeafNode | VisNode) => sum + Number(node.data[column]),
            0,
          )
        }
      }
      return field
    }
  }

  get formatValueByColumn() {
    return (column: string, value = this.fieldValueForColumn(column)) => {
      const field = typedStore.visualisationApp.field(column)
      if (field) {
        if (field.type === FieldTypes.MONETARY || ('isMonetary' in field && field.isMonetary)) {
          return typedStore.visualisationApp.monetaryFormatting(+value)
        } else if (field.type === FieldTypes.NUMERIC || field.type === FieldTypes.CALCULATION) {
          const unit = (field.unit && ` ${field.unit}`) || ''
          return `${typedStore.visualisationApp.numericFormatting(+value)}${unit}`
        }
        return value
      }
      return field
    }
  }

  get formatValueByTotalUnit() {
    return (value: number) => {
      if (
        typedStore.visualisationApp.totalFields.every(({ type }) => type === FieldTypes.MONETARY)
      ) {
        return typedStore.visualisationApp.monetaryFormatting(value)
      }
      if (
        typedStore.visualisationApp.totalFields.every(({ type }) => type === FieldTypes.NUMERIC) &&
        typedStore.visualisationApp.uniqueTotalNumericUnits.length === 1
      ) {
        const formatted = typedStore.visualisationApp.numericFormatting(value)
        const unit = typedStore.visualisationApp.uniqueTotalNumericUnits[0]
          ? ` ${typedStore.visualisationApp.uniqueTotalNumericUnits[0]}`
          : ''
        return formatted + unit
      }
      return typedStore.visualisationApp.numericFormatting(value)
    }
  }

  get fieldLeafData() {
    return (path: Path, field: string | null) => {
      return this.leafData(path)
        .map((leaf) => leaf.data[field!])
        .filter((data) => data !== undefined)
    }
  }

  get fieldLeafDataUnique() {
    return (path: Path, field: string | null) => {
      return Array.from(new Set(this.fieldLeafData(path, field)))
    }
  }

  get filteredFieldLeafDataUnique() {
    return (path: Path, field: string, filter: (node: LeafNode) => boolean) => {
      return Array.from(
        new Set(
          this.leafData(path)
            .filter(filter)
            .map((leaf) => leaf.data[field!])
            .filter((data) => data !== undefined),
        ),
      )
    }
  }

  @Mutation
  setDataLoaded(value: boolean) {
    this.dataLoaded = value
  }

  @Action({ rawError: true })
  async getTreeData() {
    this.setDataLoaded(false)

    const excludedNodesNames =
      typedStore.activeVisualisation.visualisationStore.entities.nodes.hiddenNodes
        .filter((x) => {
          const nodeIsData = x.config?.nodeType === NodeTypes.DATA
          const nodeIsUndefined = x.config?.nodeType === undefined
          const nodeIsRootPath = x.path === PATH_ROOT
          const nodeIsSubRootPath = x.path === PATH_SUBROOT
          return (nodeIsData || nodeIsUndefined) && !nodeIsRootPath && !nodeIsSubRootPath
        })
        .map((x) => x.config!.name)
    const data = await getTree(
      typedStore.activeVisualisation.visualisationAppRank,
      excludedNodesNames,
    )
    if (data) {
      this.setTreeRoot(data)
    } else {
      typedStore.activeVisualisation.visualisationStore.app.setAppLoadFailed(true)
    }

    this.setDataLoaded(true)
  }

  get filteredRootVisTreeNode(): VisTreeNode {
    return this.buildVisTree(this.filteredTreeRoot)
  }

  get buildVisTree() {
    return (treeNode: TreeNode) => {
      let visNode: VisTreeNode
      if (treeNode.path.isRoot) {
        visNode = new VisNode(treeNode.name, treeNode.path, {}, 0, null, '', 0, true) as VisTreeNode
      } else {
        visNode = this.buildVisNode(treeNode) as VisTreeNode
      }

      visNode.level = treeNode.level

      if (treeNode.isLeafNode) {
        visNode.value = isNaN(visNode.total) ? 0 : visNode.total
      } else {
        visNode.children = treeNode.children?.map((child) => this.buildVisTree(child))
      }

      return visNode
    }
  }

  get path() {
    return this.storyPath.toString()
  }

  get storyPath() {
    return typedStore.activeVisualisation.storyPath
  }

  get fieldMap() {
    return typedStore.activeVisualisation.visualisationStore.app.config.fieldMap
  }

  get levels() {
    return typedStore.activeVisualisation.visualisationStore.app.levels.levels
  }

  get clusterHeadersHash(): ClustersHash {
    return generateHashOfPaths(this.unfilteredNodeGroups.main.map((g) => g.ancestorPath))
  }

  get viewerLevel(): number {
    return this.storyPath.level
  }

  get mainLevel(): number {
    return determineMainLevel(this.levels, this.viewerLevel)
  }

  get headerLevel(): number {
    return determineHeaderLevel(this.levels, this.viewerLevel, this.mainLevel)
  }

  get sideLevel(): number {
    return this.levels.find((f) => f.showBubbles)?.level || this.levelCount
  }

  get level1Configs(): Level1Config {
    const { app } = typedStore.activeVisualisation.visualisationStore
    const { nodes } = app
    return (
      this.treeRoot.children
        ?.map((n) => n.path.level1)
        .reduce((acc, l1) => {
          acc[l1] = {
            scaleFactor: app.config.appConfig.customiseTabScaleFactor
              ? nodes.scaleFactor(l1)
              : DEFAULT_TAB_SCALE_FACTOR,
            colour: nodes.colour(l1),
          }
          return acc
        }, {} as Level1Config) || {}
    )
  }

  get filterState() {
    const config = typedStore.activeVisualisation.visualisationStore.app.config
    const { leafNodeFilter, isFiltering } = typedStore.public.search
    return {
      isFiltering,
      leafNodeFilter,
      isHidden: config?.searchSettings?.filterDisplayType === FilterDisplayTypes.HIDDEN,
    }
  }

  get filteredTreeRoot(): TreeNode {
    const { isFiltering, leafNodeFilter, isHidden } = this.filterState
    if (isFiltering && isHidden) {
      return this.treeRoot.filterLeafNodes(leafNodeFilter) || this.treeRoot
    }
    return this.treeRoot
  }

  get subRootNodeGroups(): NodeGroups {
    return {
      main: this.groupVisNodesAtLevel(
        1,
        this.getVisNodesAtLevel(this.filteredTreeRoot, determineMainLevel(this.levels, 0)),
      ),
      side: [],
    }
  }

  get rootNodeGroups(): NodeGroups {
    return {
      main: [
        {
          ancestorPath: PATH_ROOT,
          nodes: this.getVisNodesAtLevel(this.filteredTreeRoot, this.mainLevel),
        },
      ],
      side: [],
    }
  }

  get unfilteredSubRootNodeGroups(): NodeGroups {
    return {
      main: this.groupVisNodesAtLevel(
        1,
        this.getVisNodesAtLevel(this.treeRoot, determineMainLevel(this.levels, 0)),
      ),
      side: [],
    }
  }

  get unfilteredRootNodeGroups(): NodeGroups {
    return {
      main: [
        {
          ancestorPath: PATH_ROOT,
          nodes: this.getVisNodesAtLevel(this.treeRoot, this.mainLevel),
        },
      ],
      side: [],
    }
  }

  get unfilteredTraversedNodeGroups(): NodeGroups {
    return this.buildTraversedNodeGroups(
      this.treeRoot,
      this.storyPath,
      this.mainLevel,
      this.sideLevel,
      this.headerLevel,
    )
  }

  get traversedNodeGroups(): NodeGroups {
    return this.buildTraversedNodeGroups(
      this.filteredTreeRoot,
      this.storyPath,
      this.mainLevel,
      this.sideLevel,
      this.headerLevel,
    )
  }

  get findNodeByNameAndLevel() {
    return (name: string, level: number) => {
      const findNode = (node: TreeNode): TreeNode | null => {
        if (node.name === name && node.level === level) {
          return node
        }
        if (node.children) {
          for (const child of node.children) {
            const found = findNode(child)
            if (found) {
              return found
            }
          }
        }
        return null
      }
      return findNode(this.treeRoot)
    }
  }

  get buildTraversedNodeGroups() {
    return (
      treeRoot: TreeNode,
      pathToTraverse: Path,
      mainLevel: number,
      sideLevel: number,
      headerLevel: number,
    ) => {
      const mainGroups: Record<string, VisNode[]> = {}
      const side: VisNode[] = []
      const shownLevels = new Set(this.levels.filter((l) => l.showBubbles).map((f) => f.level))
      treeRoot.traverse((node) => {
        const subPathMatch = node.path.sharesAncestorAtLevel(pathToTraverse, node.level)
        const isMainLevel = node.level === mainLevel
        const isLevelShown = shownLevels.has(node.level)
        if (node.level === 1 && !subPathMatch) {
          side.push(...this.getVisNodesAtLevel(node, sideLevel))
          return false
        }
        if (isLevelShown && !subPathMatch && !isMainLevel) {
          const visNode = this.buildVisNode(node)
          if (this.hasValidTotal(visNode)) {
            side.push(visNode)
          }
          return false
        }
        if (isMainLevel) {
          const headerPath = node.path.ancestor(headerLevel)
          mainGroups[headerPath] = [
            ...(mainGroups[headerPath] ?? []),
            ...this.getVisNodesAtLevel(node, mainLevel),
          ]
          return false
        }
        return true
      })
      return {
        main: Object.keys(mainGroups).map((ancestorPath) => ({
          ancestorPath,
          nodes: mainGroups[ancestorPath],
        })),
        side,
      }
    }
  }

  get unfilteredNodeGroups(): NodeGroups {
    const { visualisation } = typedStore.activeVisualisation.visualisationStore.app.config
    if (visualisation) {
      if (this.path === PATH_SUBROOT || (this.path === PATH_ROOT && this.mainLevel === 1)) {
        return this.unfilteredSubRootNodeGroups
      } else if (this.path === PATH_ROOT) {
        return this.unfilteredRootNodeGroups
      } else {
        return this.unfilteredTraversedNodeGroups
      }
    } else {
      return {
        main: [],
        side: [],
      }
    }
  }

  get nodeGroups(): NodeGroups {
    const { visualisation } = typedStore.activeVisualisation.visualisationStore.app.config
    if (visualisation) {
      if (
        this.levels.length === 1 ||
        this.path === PATH_SUBROOT ||
        (this.path === PATH_ROOT && this.mainLevel === 1)
      ) {
        return this.subRootNodeGroups
      } else if (this.path === PATH_ROOT) {
        return this.rootNodeGroups
      } else {
        return this.traversedNodeGroups
      }
    } else {
      return {
        main: [],
        side: [],
      }
    }
  }

  get buildVisNode() {
    return (node: TreeNode): VisNode => {
      const descendants: LeafNode[] = this.getLeafData(node)
      const { name, path } = node
      return this.buildVisNodeFromLeafNodes(descendants, name, path)
    }
  }

  get buildVisNodeFromLeafNodes() {
    return (leafNodes: LeafNode[], name: string, path: Path): VisNode => {
      const { scaleFactor, colour } = this.level1Configs[path.toString().split('/')[0]]
      const data = this.aggregateLeafNodeData(leafNodes)
      const totalString = typedStore.visualisationApp.totalString({
        path,
        nodes: leafNodes,
      })

      const { isFiltering, leafNodeFilter, isHidden } = this.filterState
      const isTranslucent = isFiltering && !isHidden && !leafNodes.some(leafNodeFilter)
      const total = this.getTotal(data)
      const scaledTotal = total * scaleFactor
      return new VisNode(name, path, data, total, colour, totalString, scaledTotal, isTranslucent)
    }
  }

  get getTotal() {
    return (data: Record<string, string>) =>
      this.fieldMap
        .filter((f) => f.total)
        .reduce((total, field) => total + Number(data[field.column]), 0)
  }

  get groupVisNodesAtLevel(): (level: number, data: VisNode[]) => NodeGroup[] {
    return (level: number, data: VisNode[]): NodeGroup[] => {
      const groupByEntries: [string, VisNode[]][] = Object.entries(
        _groupBy(data, (d: VisNode) => d.path.ancestor(level)),
      )
      return groupByEntries.map(([ancestorPath, nodes]) => ({ ancestorPath, nodes }))
    }
  }

  get getVisNodesAtLevel() {
    return (node: TreeNode, level: number) => {
      const result: TreeNode[] = []
      const traverse = (node: TreeNode) => {
        if (node.level === level) {
          result.push(node)
        } else {
          for (const child of node.children || []) {
            traverse(child)
          }
        }
      }
      traverse(node)
      return result
        .map((n) => this.buildVisNode(n))
        .filter((visNode) => this.hasValidTotal(visNode))
    }
  }

  get getLeafData() {
    return (tree: TreeNode): LeafNode[] => {
      const result: LeafNode[] = []
      const traverseTree = (currentNode: TreeNode) => {
        if (!currentNode.children) {
          result.push(currentNode.asLeafNode)
        } else {
          for (const child of currentNode.children) {
            traverseTree(child)
          }
        }
      }
      if (tree) traverseTree(tree)
      return result
    }
  }

  get aggregateLeafNodeData() {
    return (nodes: LeafNode[]): Record<string, string> => {
      const aggregates = nodes.reduce(
        (
          data: { numbers: Record<string, number>; strings: Record<string, Set<string>> },
          node: LeafNode,
        ) => {
          const { numbers, strings } = data
          for (const { column, type } of this.fieldMap) {
            if (type === FieldTypes.MONETARY || type === FieldTypes.NUMERIC) {
              numbers[column] = (numbers[column] || 0) + Number(node.data[column])
            } else if (type === FieldTypes.STRING) {
              if (!strings[column]) strings[column] = new Set<string>()
              strings[column].add(node.data[column] as string)
            }
          }
          return data
        },
        { numbers: {}, strings: {} },
      )

      return {
        ...Object.keys(aggregates.numbers).reduce((acc: Record<string, string>, key: string) => {
          acc[key] = aggregates.numbers[key].toString()
          return acc
        }, {}),
        ...Object.keys(aggregates.strings).reduce((acc: Record<string, string>, key: string) => {
          const strings = Array.from(aggregates.strings[key])
          acc[key] = strings.slice(0, 3).join(', ') + (strings.length > 3 ? '...' : '')
          return acc
        }, {}),
      }
    }
  }

  get firstLeafVisNode() {
    return this.findLeafVisNode(this.treeRoot) ?? this.buildVisNode(this.treeRoot)
  }

  get findLeafVisNode(): (treeNode: TreeNode) => VisNode | null {
    return (treeNode: TreeNode) => {
      if (!treeNode.children) {
        const visNode = this.buildVisNode(treeNode)
        if (this.hasValidTotal(visNode)) {
          return visNode
        }
        return null
      }

      for (const child of treeNode.children) {
        const leaf = this.findLeafVisNode(child)
        if (leaf) {
          return leaf
        }
      }

      return null
    }
  }

  get hasValidTotal() {
    return (obj: { total: number }) => {
      return (
        obj.total !== 0 ||
        typedStore.activeVisualisation.visualisationStore.app.config.bubbleParams
          .showZeroValueBubbles
      )
    }
  }

  get d3data(): VisNode[] {
    const config = typedStore.activeVisualisation.visualisationStore.app.config
    if (config.visualisation) {
      const data =
        config.visualisation.type === VisualisationTypes.SUNBURST
          ? this.filteredLeafDataForRoot
          : config?.searchSettings?.filterDisplayType === FilterDisplayTypes.HIDDEN
          ? this.filteredLeafDataForRoot
          : this.leafDataForRoot
      const { customiseTabScaleFactor } = config.appConfig

      return data.map((item) => {
        const pathItems = item.path.parts
        const level1 = pathItems[0]
        const level2 = level1 + '/' + pathItems[1]
        const totalString = typedStore.visualisationApp.totalString({ path: item.path })
        const scaleFactor = customiseTabScaleFactor
          ? typedStore.activeVisualisation.visualisationStore.entities.nodes.scaleFactor(level1)
          : DEFAULT_TAB_SCALE_FACTOR
        const total = this.getTotal(item.data)
        return {
          ...item,
          recordId: Number(item.recordId),
          path: item.path,
          total,
          colour: typedStore.activeVisualisation.visualisationStore.entities.nodes.colour(level1),
          level1,
          level2,
          totalString,
          scaledTotal: total * scaleFactor,
          isTranslucent: false,
        }
      })
    } else return []
  }

  get bubblesConfig(): BubbleConfig {
    const {
      bubbleParams,
      customBubblesGroups,
      haloBubbles,
      hollowBubbles,
      searchSettings,
      fieldMap,
    } = typedStore.activeVisualisation.visualisationStore.app.config
    return {
      bubbleParams,
      customBubblesGroups,
      haloBubbles,
      hollowBubbles,
      filterLightness: searchSettings?.lightness,
      bubblePositions: this.bubblePositions,
      fieldMap,
    }
  }

  get bubblesConfigV2(): BubblesConfigV2 {
    const {
      bubbleParams,
      customBubblesGroups,
      haloBubbles,
      hollowBubbles,
      searchSettings,
      fieldMap,
      bubblesClustersSorting,
    } = typedStore.activeVisualisation.visualisationStore.app.config
    return {
      bubbleParams,
      customBubblesGroups,
      haloBubbles,
      hollowBubbles,
      filterLightness: searchSettings?.lightness,
      fieldMap,
      clustersSorting: bubblesClustersSorting,
    }
  }

  get bubblePositions() {
    return this.positions(NodePositionsKeys.BUBBLE_POSITIONS, NodePositionKeys.BUBBLE_POSITION)
  }

  get headerPositions() {
    return this.positions(NodePositionsKeys.HEADER_POSITIONS, NodePositionKeys.HEADER_POSITION)
  }

  private get positions(): (
    positionsKey: NodePositionsKey,
    positionKey: NodePositionKey,
  ) => Record<string, VisPositions> {
    return (positionsKey: NodePositionsKey, positionKey: NodePositionKey) => {
      const initialResult: Record<string, VisPositions> = {}
      return typedStore.activeVisualisation.visualisationStore.entities.nodes.allNodes.reduce(
        (result, item) => {
          let positions: VisPositions = defaultVisPositions()

          if (item.config) {
            if (positionsKey in item.config) {
              positions = item.config[positionsKey]!
            } else {
              if (positionKey in item.config) {
                const [x, y] = item.config[positionKey]
                positions.default = { x, y }
              }
            }
          }

          result[item.path] = positions
          return result
        },
        initialResult,
      )
    }
  }

  get bubbleRadiusScale() {
    const allNodes = [...this.nodeGroups.main.flatMap((g) => g.nodes), ...this.nodeGroups.side]
    const totals = allNodes.map((i) => Math.abs(i.total))
    const { bubbleMinAuto, bubbleMax, bubbleMin } = this.bubblesConfig.bubbleParams
    const min = bubbleMinAuto ? 0 : bubbleMin
    const domainMin = bubbleMinAuto ? 0 : Math.min(...totals)
    const domainMax = Math.max(...totals)

    return (
      scalePow()
        .exponent(0.5)
        // disable extrapolation outside of the domain to avoid returning negative numbers
        .clamp(true)
        .domain([domainMin, domainMax])
        .range([min, bubbleMax])
    )
  }

  get allNonLeafPaths(): string[] {
    const paths: string[] = []

    this.treeRoot.traverse((node) => {
      paths.push(node.path.toString())

      return node.level !== this.levels.length - 1
    })

    return paths
  }

  get clusterCollectionsByClustersHash(): Map<ClustersHash, ClusterAndHeaderBoxCollection> {
    const clusterCollectionsByClustersHash: Map<number, ClusterAndHeaderBoxCollection> = new Map()

    // Get subroot clusters
    const { main: groups } = this.subRootNodeGroups
    const subRootBoxes = groups.map(
      ({ ancestorPath, nodes }) =>
        new ClusterAndHeaderBox(ancestorPath, nodes, this.bubblesConfig, this.storyPath),
    )
    const subRootCollection = new ClusterAndHeaderBoxCollection(subRootBoxes)
    clusterCollectionsByClustersHash.set(
      generateHashOfPaths(subRootBoxes.map((box) => box.path)),
      subRootCollection,
    )

    // Get clusters for all non-leaf paths at each viewer level
    const viewerLevels = this.levels.slice(0, this.levels.length - 1).map((f) => f.level)

    for (const viewerLevel of viewerLevels) {
      const mainLevel = determineMainLevel(this.levels, viewerLevel)
      const headerLevel = determineHeaderLevel(this.levels, viewerLevel, mainLevel)
      // We're not concerned about side nodes so just use side level 0
      const sideLevel = 0

      for (const path of this.getNodePathsAtLevel(this.treeRoot, viewerLevel)) {
        const { main: groups } = this.buildTraversedNodeGroups(
          this.treeRoot,
          path,
          mainLevel,
          sideLevel,
          headerLevel,
        )
        const hash = generateHashOfPaths(groups.map(({ ancestorPath }) => ancestorPath))
        if (clusterCollectionsByClustersHash.has(hash)) {
          continue
        }
        const boxes = groups.map(
          ({ ancestorPath, nodes }) =>
            new ClusterAndHeaderBox(ancestorPath, nodes, this.bubblesConfig, this.storyPath),
        )
        const collection = new ClusterAndHeaderBoxCollection(boxes)

        clusterCollectionsByClustersHash.set(hash, collection)
      }
    }

    return clusterCollectionsByClustersHash
  }

  get getNodePathsAtLevel() {
    return (node: TreeNode, level: number) => {
      const result: Path[] = []
      const traverse = (node: TreeNode) => {
        if (node.level === level) {
          result.push(node.path)
        } else {
          for (const child of node.children || []) {
            traverse(child)
          }
        }
      }
      traverse(node)
      return result
    }
  }
}

function determineMainLevel(levels: LevelField[], viewerLevel: number) {
  const levelCount = levels.length
  return (
    levels.find((f) => f.showBubbles && (f.level > viewerLevel || f.level === levelCount))?.level ||
    levelCount
  )
}

function determineHeaderLevel(
  levels: LevelField[],
  viewerLevel: number,
  mainLevel: number,
): number {
  const levelCount = levels.length
  const level1 = levels.find((x) => x.level === 1)!
  // If the viewerLevel is subroot, return level 1
  if (viewerLevel === 0) {
    return level1.level
  }

  const bubbleLevelsCount = levels.filter((level) => level.showBubbles).length

  // If the only level shown as bubbles is the level of the lowest order
  // or the viewerLevel is the level of the lowest order,
  // then return the level of the lowest order - 1
  if (bubbleLevelsCount === 1 || viewerLevel === levelCount) {
    return levelCount - 1 // Return level of the lowest order - 1
  }

  const level2 = levels.find((x) => x.level === 2)!

  // If level 2 exists and viewerLevel is 1 and level 1 is shown as bubbles but level 2 isn't shown as bubbles
  // then return level 2
  if (level2 && viewerLevel === 1 && level1.showBubbles && !level2.showBubbles) {
    return level2.level
  }

  const mainField = levels.find((x) => x.level === mainLevel)
  return mainField?.headerPerBubble ? mainLevel : viewerLevel
}

export const VisualisationDataModule = getModule(VisualisationData)
