import { NODE_CONFIG_KEY } from '@/constants'

export const AppTypes = {
  BUBBLES: 'bubbles',
  RATES_CALC: 'rates-calc',
  CONSOLIDATED: 'consolidated',
}

export type AppType = (typeof AppTypes)[keyof typeof AppTypes]

export const AppRanks = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
}

export type AppRank = (typeof AppRanks)[keyof typeof AppRanks]

export type ConfigKey = typeof NODE_CONFIG_KEY

export const AppStatuses = {
  DISABLED: -1,
  INITIAL: 0,
  PENDING: 1,
  LIVE: 2,
  INTERNAL: 3,
} as const

export type AppStatus = (typeof AppStatuses)[keyof typeof AppStatuses]

export const AwsMarketplaceCustomerSubscriptionStatuses = {
  CREATED: 'created',
  CHANGED: 'changed',
  UNCHANGED: 'unchanged',
  INACTIVE: 'inactive',
} as const

export type AwsMarketplaceCustomerSubscriptionStatus =
  (typeof AwsMarketplaceCustomerSubscriptionStatuses)[keyof typeof AwsMarketplaceCustomerSubscriptionStatuses]

export const BasemapTypes = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
} as const

export type BasemapType = (typeof BasemapTypes)[keyof typeof BasemapTypes]

export const ButtonStates = {
  ICON: 0,
  PROCESSING: 1,
  SUCCESS: 2,
  FAILURE: 3,
} as const

export type ButtonState = (typeof ButtonStates)[keyof typeof ButtonStates]

export const ComparisonEqualityOperators = {
  LESS_THAN: '<',
  GREATER_THAN: '>',
  LESS_THAN_OR_EQUAL: '<=',
  GREATER_THAN_OR_EQUAL: '>=',
  STRICT_EQUAL: '===',
  STRICT_UNEQUAL: '!==',
} as const

export type ComparisonEqualityOperator =
  (typeof ComparisonEqualityOperators)[keyof typeof ComparisonEqualityOperators]

export const CustomBubblesCompareCategory = 'category'

export type CustomBubblesCompare = typeof CustomBubblesCompareCategory

export const FeatureKeys = {
  USE_N_LEVELS: 'use-n-levels',
  FEEDBACK: 'feedback',
  FEEDBACK_KIOSK_USER_WAIT_DURATION: 'feedback-kiosk-user-wait-duration',
  NESTED_BUBBLES: 'nested-bubbles',
  USE_BUBBLES_V2: 'use-bubbles-v2',
  XY_BUBBLES: 'xy-bubbles',
} as const

export type Feature = (typeof FeatureKeys)[keyof typeof FeatureKeys]

export const FieldCalculationFunctionTypes = {
  SUM_OF_ALL: 'sum_of_all',
  SUM: 'sum',
  MAX: 'max',
  MIN: 'min',
  MEAN: 'mean',
  COUNT: 'count',
} as const

export type FieldCalculationFunctionType =
  (typeof FieldCalculationFunctionTypes)[keyof typeof FieldCalculationFunctionTypes]

export const FieldCalculationOperatorTypes = {
  PLUS: '+',
  MINUS: '-',
  MULTIPLY: '*',
  DIVIDE_BY: '/',
  OPEN_PARENTHESIS: '(',
  CLOSE_PARENTHESIS: ')',
} as const

export type FieldCalculationOperatorType =
  (typeof FieldCalculationOperatorTypes)[keyof typeof FieldCalculationOperatorTypes]

export const FeedbackRatingTypes = {
  NONE: 'none',
  THUMBS: 'thumbs',
  VOTES: 'votes',
  STARS: 'stars',
  FACES: 'faces',
} as const

export type FeedbackRatingType = (typeof FeedbackRatingTypes)[keyof typeof FeedbackRatingTypes]

export const FieldCalculationTypes = {
  FUNCTION: 'function',
  OPERATOR: 'operator',
  NUMBER: 'number',
} as const

export type FieldCalculationType =
  (typeof FieldCalculationTypes)[keyof typeof FieldCalculationTypes]

export const FieldTypes = {
  MONETARY: 'monetary',
  NUMERIC: 'numeric',
  STRING: 'string',
  CALCULATION: 'calculation',
} as const

export type FieldType = (typeof FieldTypes)[keyof typeof FieldTypes]

export const FilterDisplayTypes = {
  HIDDEN: 'hidden',
  TRANSLUCENT: 'transparent',
} as const

export type FilterDisplayType = (typeof FilterDisplayTypes)[keyof typeof FilterDisplayTypes]

export const HollowBubbleDisplays = {
  NONE: 'none',
  NEGATIVE: 'negative',
  POSITIVE: 'positive',
  CATEGORY: 'category',
} as const

export type HollowBubbleDisplay = (typeof HollowBubbleDisplays)[keyof typeof HollowBubbleDisplays]

export const LegendPositions = {
  TOP_LEFT: 'top-left',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  TOP_RIGHT_BUTTON: 'top-right-button',
} as const

export type LegendPosition = (typeof LegendPositions)[keyof typeof LegendPositions]

export const NavigationButtonDisplays = {
  ON: 'on',
  DROPDOWN: 'dropdown',
} as const

export type NavigationButtonDisplay =
  (typeof NavigationButtonDisplays)[keyof typeof NavigationButtonDisplays]

// Nodes
export const NodeConfigKeys = {
  ICON: 'icon',
  NAME: 'name',
  ORDER: 'order',
  COLOUR: 'colour',
  FONT_COLOUR: 'fontColour',
  LABEL: 'label',
  BUBBLE_POSITION: 'bubblePosition',
  HEADER_POSITION: 'headerPosition',
  BUBBLE_POSITIONS: 'bubblePositions',
  HEADER_POSITIONS: 'headerPositions',
  SCALE_FACTOR: 'scaleFactor',
  VISIBLE: 'visible',
  NODE_TYPE: 'nodeType',
} as const

export type NodeConfigKey = (typeof NodeConfigKeys)[keyof typeof NodeConfigKeys]

export const NodePositionKeys = {
  BUBBLE_POSITION: NodeConfigKeys.BUBBLE_POSITION,
  HEADER_POSITION: NodeConfigKeys.HEADER_POSITION,
} as const

export type NodePositionKey = (typeof NodePositionKeys)[keyof typeof NodePositionKeys]

export const NodePositionsKeys = {
  BUBBLE_POSITIONS: NodeConfigKeys.BUBBLE_POSITIONS,
  HEADER_POSITIONS: NodeConfigKeys.HEADER_POSITIONS,
} as const

export type NodePositionsKey = (typeof NodePositionsKeys)[keyof typeof NodePositionsKeys]

export const NodeTypes = {
  CUSTOM: 'custom',
  DATA: 'data',
  ROOT: 'root',
  APP: 'application',
} as const

export type NodeType = (typeof NodeTypes)[keyof typeof NodeTypes]

export const OrganisationStatuses = {
  ENABLED: 1,
  DISABLED: -1,
} as const

export type OrganisationStatus = (typeof OrganisationStatuses)[keyof typeof OrganisationStatuses]

export type SplashScreenWidth = 'sm' | 'md' | 'lg' | 'xl'

export const SplashScreenDisplays = {
  BOTH: 'both',
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
} as const

export type SplashScreenDisplay = (typeof SplashScreenDisplays)[keyof typeof SplashScreenDisplays]

// Nodes
export const StoryKeys = {
  ABOVE_BUBBLES: 'aboveBubbles',
  ABOVE_MAP: 'aboveMap',
  SIDEBAR_TOP: 'sidebarTop',
  SIDEBAR_BOT: 'sidebarBot',
  CUSTOM_TITLE: 'customTitle',
  CUSTOM_DESCRIPTION: 'customDescription',
  INFO_FAQ: 'faq',
  SPLASH_SCREEN: 'splashscreen',
} as const

export type StoryKey = (typeof StoryKeys)[keyof typeof StoryKeys]

export const StoryPaths = {
  PATH_ROOT: 'root',
  PATH_SUBROOT: 'subroot',
} as const

export type StoryPath = (typeof StoryPaths)[keyof typeof StoryPaths]

export const SummaryTableOptions = {
  NONE: 'child',
  SUMMARY: 'summary',
  CHILD: 'none',
} as const

export type SummaryTableOption = (typeof SummaryTableOptions)[keyof typeof SummaryTableOptions]

export const UserStatuses = {
  DISABLED: -1,
  ENABLED: 1,
} as const

export type UserStatus = (typeof UserStatuses)[keyof typeof UserStatuses]

export const VersionStatuses = {
  INITIAL: 0,
  BUILT: 1,
  ARCHIVED: 2,
} as const

export type VersionStatus = (typeof VersionStatuses)[keyof typeof VersionStatuses]

export const VisualisationTypes = {
  BUBBLES: 'bubbles',
  NESTED_BUBBLES: 'nestedBubbles',
  SUNBURST: 'sunburst',
  MAP: 'map',
  XY_BUBBLES: 'xyBubbles',
  CUSTOM: 'custom',
  NONE: 'none',
} as const

export type VisualisationType = (typeof VisualisationTypes)[keyof typeof VisualisationTypes]

export const CustomTabConfigKeys = {
  SHOW_INFO_CARD: 'showInfoCard',
  INFO_CARD_SIZE: 'infoCardSize',
  BACKGROUND_IMAGE: 'backgroundImage',
  INFO_CARD_CONTENT_PANELS: 'infoCardContentPanels',
  INFO_CARD_OPACITY: 'infoCardOpacity',
  INFO_CARD_COLOUR: 'infoCardColour',
  INFO_CARD_CONTENT_PANELS_OPACITY: 'infoCardContentPanelsOpacity',
  INFO_CARD_CONTENT_PANELS_COLOUR: 'infoCardContentPanelsColour',
} as const

export const InfoCardContentPanelKeys = {
  LABEL: 'label',
  CONTENT: 'content',
  HIDE_BORDER: 'hideBorder',
  LINK_TYPE: 'linkType',
  LINK: 'link',
  TOOLTIP: 'tooltip',
} as const

export const InfoCardSizes = {
  AUTO: 'auto',
  FILL: 'fill',
} as const

export type InfoCardSize = (typeof InfoCardSizes)[keyof typeof InfoCardSizes]

export const InfoCardContentPanelLinkTypes = {
  EXTERNAL: 'external',
  INTERNAL: 'internal',
}

export type InfoCardContentPanelLinkType =
  (typeof InfoCardContentPanelLinkTypes)[keyof typeof InfoCardContentPanelLinkTypes]

export const TemplateTypes = {
  BASE: 'base',
  PRESENTATION: 'presentation',
}

export type TemplateType = (typeof TemplateTypes)[keyof typeof TemplateTypes]

export const PopupConfigTypes = {
  GLOBAL: 'GLOBAL',
  PER_LEVEL: 'PER_LEVEL',
}

export type PopupConfigType = (typeof PopupConfigTypes)[keyof typeof PopupConfigTypes]

export const NavButtonKeys = {
  BUBBLES: 'bubbles',
  DOWNLOAD: 'download',
  FILTER: 'filterByCategory',
  INFO: 'info',
  MAP: 'map',
  MENU: 'menu',
  NESTED_BUBBLES: 'nestedBubbles',
  SEARCH: 'search',
  VISUALISATIONS: 'visualisations',
  SUNBURST: 'sunburst',
  XY_BUBBLES: 'xyBubbles',
}

export type NavButtonKey = (typeof NavButtonKeys)[keyof typeof NavButtonKeys]

export const NavButtonNames: { [key in NavButtonKey]: string } = {
  [NavButtonKeys.BUBBLES]: 'Bubbles',
  [NavButtonKeys.DOWNLOAD]: 'Download',
  [NavButtonKeys.FILTER]: 'Filter',
  [NavButtonKeys.INFO]: 'Info',
  [NavButtonKeys.MAP]: 'Map',
  [NavButtonKeys.MENU]: 'Menu',
  [NavButtonKeys.NESTED_BUBBLES]: 'Nested Bubbles',
  [NavButtonKeys.SEARCH]: 'Search',
  [NavButtonKeys.VISUALISATIONS]: 'Visualisations',
  [NavButtonKeys.SUNBURST]: 'Sunburst',
}

export const NavButtonDisplayTypes = {
  STATIC: 'static',
  STANDALONE: 'standalone',
  MENU_ITEM: 'menuItem',
  VISUALISATION: 'visualisation',
} as const

export type NavButtonDisplayType =
  (typeof NavButtonDisplayTypes)[keyof typeof NavButtonDisplayTypes]

export const SortDirection = {
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
} as const

export type SortDirection = (typeof SortDirection)[keyof typeof SortDirection]
