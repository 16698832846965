import { getFeedback, postComment, postRating } from '@/api'
import { PATH_SEPARATOR } from '@/constants'
import logger from '@/plugins/logging/logger'
import router from '@/router'
import typedStore from '@/store/typedStore'
import { AppRank, AppRanks, FeedbackItem, Path, RatingResponse } from '@/types'
import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'

export interface FeedbackRecord {
  feedbackItem: FeedbackItem
  ratingResults: RatingResponse[]
}

export interface PathFeedbackRecord {
  [path: string]: FeedbackRecord
}

export interface QuestionFeedbackRecord {
  [feedbackQuestionId: string]: PathFeedbackRecord
}

export interface AppFeedbackRecord {
  [appId: number]: QuestionFeedbackRecord
}

export interface IFeedbackState {
  feedback: AppFeedbackRecord
  isKiosk: boolean
}

export const useFeedbackStore = defineStore({
  id: 'feedback',
  state: (): IFeedbackState => ({
    feedback: {},
    isKiosk: false,
  }),
  getters: {
    getSessionId() {
      if (this.isKiosk) {
        return uuidv4
      } else {
        const sessionIdKey = 'feedback_session_id'
        const existing = window.sessionStorage.getItem(sessionIdKey)
        if (existing) {
          return () => existing
        }
        const sessionId = uuidv4()
        window.sessionStorage.setItem(sessionIdKey, sessionId)
        return () => sessionId
      }
    },
  },
  actions: {
    determineIsKiosk() {
      const kioskKey = 'kiosk'
      const fromRoute = kioskKey in router.currentRoute.query
      const fromLocalStorage = !!localStorage.getItem(kioskKey)
      if (fromRoute || fromLocalStorage) {
        localStorage.setItem(kioskKey, 'true')
        this.isKiosk = true
      }
    },
    ensureFeedbackStructure(appId: number, feedbackQuestionId: string, path: string) {
      // Ensure the appId level is reactive
      if (!this.feedback[appId]) {
        Vue.set(this.feedback, appId, {})
      }

      // Ensure the feedbackQuestionId level is reactive
      const appFeedback = this.feedback[appId]
      if (!appFeedback[feedbackQuestionId]) {
        Vue.set(appFeedback, feedbackQuestionId, {})
      }

      // Ensure the path level is reactive
      const questionFeedback = appFeedback[feedbackQuestionId]
      if (!questionFeedback[path]) {
        Vue.set(questionFeedback, path, {
          feedbackItem: { path, rating: null, comment: '' },
          ratingResults: [],
        })
      }
    },

    addOrUpdateFeedbackItem(
      appId: number,
      feedbackQuestionId: string,
      path: string,
      feedbackItemData: FeedbackItem,
      ratingResults?: RatingResponse[],
    ) {
      const feedbackPath = this.feedback[appId][feedbackQuestionId][path]
      Vue.set(feedbackPath, 'feedbackItem', feedbackItemData)

      if (ratingResults) {
        Vue.set(feedbackPath, 'ratingResults', ratingResults)
      }
    },

    clearRatingState(appId: number, feedbackQuestionId: string, path: string) {
      const feedbackPath = this.feedback[appId][feedbackQuestionId][path]
      if (feedbackPath) {
        Vue.set(feedbackPath, 'feedbackItem', {
          ...feedbackPath.feedbackItem,
          rating: null,
        })
      }
    },

    clearCommentState(appId: number, feedbackQuestionId: string, path: string) {
      const feedbackPath = this.feedback[appId][feedbackQuestionId][path]
      if (feedbackPath) {
        Vue.set(feedbackPath, 'feedbackItem', {
          ...feedbackPath.feedbackItem,
          comment: '',
        })
      }
    },

    getFriendlyPath(appRank: AppRank, actualPath: string): string {
      const store = appRank === AppRanks.PRIMARY ? typedStore.primary : typedStore.secondary
      const { label, name } = store.entities.nodes
      const path = new Path({ path: actualPath })
      const level1 = label(path.level1) || name(path.level1) || path.level1
      if (path.level <= 1) return level1
      const { maxLevel } = typedStore.activeVisualisation.visualisationStore.app.levels
      const isLeaf = path.level === maxLevel
      const leafLabel = (isLeaf && typedStore.primary.app.projectLabel(path)) || path.name
      return [level1, ...path.parts.slice(1, path.level - 1), leafLabel].join(PATH_SEPARATOR)
    },

    async populateExistingFeedback(appRank: AppRank, appId: number) {
      try {
        const feedbackArray = await getFeedback(appRank, this.getSessionId())

        feedbackArray.forEach((feedback) => {
          const feedbackQuestionId = feedback.feedbackQuestionId
          feedback.feedback.forEach((feedbackItem) => {
            this.ensureFeedbackStructure(appId, feedbackQuestionId, feedbackItem.path)
            this.addOrUpdateFeedbackItem(appId, feedbackQuestionId, feedbackItem.path, feedbackItem)
          })
        })
      } catch (e) {
        logger.error(`Failed to populate existing feedback: ${e}`)
      }
    },

    async addOrUpdateRating(
      appRank: AppRank,
      appId: number,
      feedbackQuestionId: string,
      path: string,
      rating: number,
    ) {
      this.ensureFeedbackStructure(appId, feedbackQuestionId, path)
      const friendlyPath = this.getFriendlyPath(appRank, path)
      const ratingResponse = await postRating(appRank, {
        questionId: feedbackQuestionId,
        sessionId: this.getSessionId(),
        path,
        friendlyPath,
        rating,
      })
      const feedbackItem = this.feedback[appId][feedbackQuestionId][path].feedbackItem
      this.addOrUpdateFeedbackItem(
        appId,
        feedbackQuestionId,
        path,
        { ...feedbackItem, rating },
        ratingResponse,
      )
    },

    async addOrUpdateComment(
      appRank: AppRank,
      appId: number,
      feedbackQuestionId: string,
      path: string,
      comment: string,
    ) {
      this.ensureFeedbackStructure(appId, feedbackQuestionId, path)

      const friendlyPath = this.getFriendlyPath(appRank, path)
      await postComment(appRank, {
        questionId: feedbackQuestionId,
        sessionId: this.getSessionId(),
        path,
        friendlyPath,
        comment,
      })
      const feedbackItem = this.feedback[appId][feedbackQuestionId][path].feedbackItem
      this.addOrUpdateFeedbackItem(appId, feedbackQuestionId, path, { ...feedbackItem, comment })
    },
  },
})
