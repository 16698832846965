import {
  Coordinate2D,
  FieldCalculationFunctionTypes,
  InfoCardSizes,
  SelectOption,
  VisualisationTypes,
} from '@/types'
import { schemeCategory10, schemePastel1 } from 'd3-scale-chromatic'

export const SERVER = process.env.VUE_APP_API_ADDRESS
export const MAINTENANCE_MODE = process.env.VUE_APP_MAINTENANCE_MODE === 'true'

export const PATH_SEPARATOR = '/'
export const PATH_ROOT = 'root'
export const PATH_SUBROOT = 'subroot'
export const VIEWER_LEVEL_SUBROOT = 0

export const VALID_HEX = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/
export const TAB_COLORS = schemeCategory10
export const PIE_COLORS = schemePastel1

export const CUSTOM_STORY_LEVEL = -1
export const MINIMAL_LENGTH_LIST = 5
export const AUTO_TOTAL_KEY = '$autoTotal'
export const MOBILE_VIEWPORT_WIDTH = 940
export const CLASS_TIPTAP_CONTAINER = 'tiptap-container'
export const DEFAULT_STORY_KEY = 'defaultStoryLevel'

// View names used in PublicView
export const VIEW_PUBLIC = 'public'
/**
 * @deprecated all visualisations go to the 'public' route
 */
export const VIEW_MAP = 'map'
export const VIEW_VISUAL = 'VisualisationView'

export const DEFAULT_PAGE_TITLE = 'OrbViz'

export const CALL_BACK_ERROR_NO_ACCOUNT = 'no account'
export const CALL_BACK_ERROR_EMAIL_NOT_VERIFIED = 'email not verified'

export const FIELD_CALC_FUNCTIONS = [
  { text: 'Sum', value: FieldCalculationFunctionTypes.SUM },
  { text: 'Total', value: FieldCalculationFunctionTypes.SUM_OF_ALL },
  { text: 'Max', value: FieldCalculationFunctionTypes.MAX },
  { text: 'Min', value: FieldCalculationFunctionTypes.MIN },
  { text: 'Average', value: FieldCalculationFunctionTypes.MEAN },
  { text: 'Count', value: FieldCalculationFunctionTypes.COUNT },
]

export const DEFAULT_TAB_SCALE_FACTOR = 1
export const TAB_OFFSET = 15
export const AUTO_TOTAL_FIELD_COLUMN = '$auto-total-field'
export const AUTO_TOTAL_FIELD_NAME = 'Total (auto generated)'

export const UNIT_TYPE_MONETARY = 'unit-type-monetary'

export const DEFAULT_NODE_TYPE = 'data'

export const DEFAULT_ACTIVE_VISUALISATION_TYPE = VisualisationTypes.BUBBLES

export const NAME_ID_SEPARATOR = ' '
export const SECONDARY_APP_PATH_PREFIX = 'sa-'

export const DEFAULT_INFO_CARD_SIZE = InfoCardSizes.AUTO
export const DEFAULT_INFO_CARD_OPACITY = 0.85
export const DEFAULT_INFO_CARD_COLOUR = '#ffffff'

export const DEFAULT_SELECT_OPTION: SelectOption = {
  value: undefined,
  text: '',
}

export const VISUALISATION_VIEWBOX_SIZE = 2000
export const VISUALISATION_VIEWBOX_POSITION_OFFSET = -1000

export const UI_EVENTS = {
  MouseMove: 'mousemove',
  MouseOver: 'mouseover',
  MouseOut: 'mouseout',
  TouchStart: 'touchstart',
  TouchEnd: 'touchend',
  TouchCancel: 'touchcancel',
  TouchMove: 'touchmove',
  Click: 'click',
  DragStart: 'dragstart',
  DragMove: 'dragmove',
  DragEnd: 'dragend',
  Tap: 'tap',
  Hold: 'hold',
}

export const CURSOR_STYLES = {
  Default: 'default',
  Pointer: 'pointer',
  Move: 'move',
  Grab: 'grab',
  Grabbing: 'grabbing',
  Crosshair: 'crosshair',
  Text: 'text',
  Wait: 'wait',
  Help: 'help',
  Progress: 'progress',
  NotAllowed: 'not-allowed',
  ZoomIn: 'zoom-in',
  ZoomOut: 'zoom-out',
  Cell: 'cell',
  Copy: 'copy',
  None: 'none',
}

export function defaultCoordinate(): Coordinate2D {
  return { x: 0, y: 0 }
}
