import MentionExtension from '@/components/TipTap/extensions/MentionExtension'
import {
  AUTO_TOTAL_FIELD_COLUMN,
  DEFAULT_STORY_KEY,
  DEFAULT_XY_BUBBLES_CONFIG,
  MONETARY_UNIT,
  PATH_ROOT,
  PIE_COLORS,
} from '@/constants'
import store from '@/store'
import { useNavButtonsStore } from '@/store/public/navButtons'
import typedStore from '@/store/typedStore'
import {
  BubbleParamsConfig,
  FieldCalculationV2Config,
  FieldMapItem,
  FieldTypes,
  isFieldCalculation,
  isFieldMonetary,
  isFieldNumeric,
  isFieldString,
  LeafNode,
  NavButtonKeys,
  NodeResponse,
  NodeStoryKeys,
  Path,
  PiechartItem,
  XYBubblesConfig,
  XYDimensionsMapping,
} from '@/types'
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface SumTotalsByUnitItem {
  type: typeof FieldTypes.MONETARY | typeof FieldTypes.NUMERIC | typeof FieldTypes.CALCULATION
  unit: string
  total: number
}

@Module({ dynamic: true, store, name: 'visualisationApp', namespaced: true })
export default class VisualisationApp extends VuexModule {
  // #region app

  get story() {
    return (path: Path, panel: NodeStoryKeys, showParent = true): string => {
      const { activeVisualisation } = typedStore
      const nodes = activeVisualisation.visualisationStore.entities.nodes.allNodes
      if (showParent && activeVisualisation.displaysTreeData) {
        return findDataNodeStory(nodes, path, panel)
      } else {
        return nodes.find((n) => n.path === path.toString())?.[panel] || ''
      }
    }
  }

  get storyEditorExtensions() {
    return [
      MentionExtension.configure({
        renderLabel: ({ node }) => typedStore.visualisationApp.mentionContent(node.attrs.id),
      }),
    ]
  }

  get uniqueNumericUnits() {
    return Array.from(new Set(this.numericFields.map(({ unit }) => unit)))
  }

  get uniqueTotalNumericUnits() {
    return Array.from(new Set(this.totalFields.filter(isFieldNumeric).map(({ unit }) => unit)))
  }

  get sumFieldsByUnit() {
    return (
      {
        path,
        leafNodes,
      }: { path?: string; leafNodes?: { data?: Record<string, string | number> }[] },
      filters?: string[],
    ): SumTotalsByUnitItem[] => {
      const leafData =
        leafNodes ||
        typedStore.visualisationData.filterLeafData(
          new Path({ path }) || typedStore.public.display.storyPath,
        )
      const numerics = Object.fromEntries(this.uniqueNumericUnits.map((unit) => [unit, 0]))
      const initialValue: { numerics: { [k: string]: number }; monetary: number } = {
        numerics,
        monetary: 0,
      }

      const summedValue = leafData.reduce((previousValue, currentValue) => {
        // Calculate for each unit for each numeric field
        this.numericFields
          .filter(({ column }) => {
            if (filters && filters.length > 0) return filters.includes(column)
            else return column
          })
          .forEach(({ unit, column }) => {
            const value = currentValue.data![column] as string
            previousValue.numerics[unit] += Number(value)
          })

        // Calculate for all monetary fields
        this.monetaryFields
          .filter(({ column }) => {
            if (filters && filters.length > 0) return filters.includes(column)
            else return column
          })
          .forEach(({ column }) => {
            const value = currentValue.data![column] as string
            previousValue.monetary += Number(value)
          })

        return previousValue
      }, initialValue)

      return [
        ...Object.entries(summedValue.numerics).map(sumTotalsByUnitItem),
        {
          type: FieldTypes.MONETARY,
          unit: MONETARY_UNIT,
          total: summedValue.monetary,
        },
      ]
    }
  }

  get sumTotalsByUnit() {
    return ({
      path,
      leafNodes,
    }: {
      path?: Path
      leafNodes?: { data: Record<string, string | number> }[]
    }): SumTotalsByUnitItem[] => {
      const leafData =
        leafNodes ||
        typedStore.visualisationData.filterLeafData(path || typedStore.public.display.storyPath)
      const filteredNumericFields = this.numericFields.filter(({ total }) => total)
      const filteredMonetaryFields = this.monetaryFields.filter(({ total }) => total)
      const numerics = Object.fromEntries(filteredNumericFields.map(({ unit }) => [unit, 0]))
      const initialValue: { numerics: { [k: string]: number }; monetary: number } = {
        numerics,
        monetary: 0,
      }

      const summedValue = leafData.reduce((previousValue, currentValue) => {
        // Calculate for each unit for each numeric field
        filteredNumericFields.forEach(({ unit, column }) => {
          const value = currentValue.data![column]
          previousValue.numerics[unit] += Number(value)
        })

        // Calculate for all monetary fields
        filteredMonetaryFields.forEach(({ column }) => {
          const value = currentValue.data![column]
          previousValue.monetary += Number(value)
        })

        return previousValue
      }, initialValue)

      const result: SumTotalsByUnitItem[] = [
        ...Object.entries(summedValue.numerics).map(sumTotalsByUnitItem),
      ]

      if (filteredMonetaryFields.length > 0) {
        result.push({
          type: FieldTypes.MONETARY,
          unit: MONETARY_UNIT,
          total: summedValue.monetary,
        })
      }

      return result
    }
  }

  get totalString() {
    return ({
      path,
      sumTotalsByUnit,
      nodes,
    }: {
      path?: Path
      sumTotalsByUnit?: SumTotalsByUnitItem[]
      nodes?: LeafNode[]
    }) => {
      if (!sumTotalsByUnit) {
        sumTotalsByUnit = this.sumTotalsByUnit({ path, leafNodes: nodes })
      }
      return sumTotalsByUnit
        .map((item) => {
          if (item.type === FieldTypes.MONETARY) {
            return this.monetaryFormatting(item.total)
          }
          if (item.type === FieldTypes.NUMERIC) {
            const value = this.numericFormatting(item.total)
            const unit = item.unit ? ` ${item.unit}` : ''
            return value + unit
          }
          return undefined
        })
        .filter((i) => i) // remove any undefined
        .join('; ')
    }
  }

  // #endregion app

  // #region config

  get bubbleParams() {
    return typedStore.activeVisualisation.visualisationStore.app.config.bubbleParams
  }

  @Mutation
  updateBubbleParams(payload: { param: keyof BubbleParamsConfig; value: any }) {
    // Payload: {'param': '', 'value': 0.1}
    // TODO: verify that Vue.set is typesafe
    Vue.set(
      typedStore.activeVisualisation.visualisationStore.app.config.bubbleParams,
      payload.param,
      payload.value,
    )
  }

  @Mutation
  addLegendValue() {
    typedStore.activeVisualisation.visualisationStore.app.config.mapConfig.legends.splice(
      typedStore.activeVisualisation.visualisationStore.app.config.mapConfig.legends.length - 1,
      0,
      {
        range: 0,
        colour: '#ffffff',
      },
    )
  }

  @Mutation
  minusLegendValue(index: number) {
    typedStore.activeVisualisation.visualisationStore.app.config.mapConfig.legends.splice(index, 1)
  }

  @Mutation
  addTotalField(column: string) {
    // Makes field.total = true
    const fieldMap = typedStore.activeVisualisation.visualisationStore.app.config.fieldMap

    // Set fieldMap total to true
    const field = fieldMap.find((f) => f.column === column)
    if (!field) {
      throw new Error(`Field with column "${column}" not found`)
    }
    field.total = true
  }

  @Mutation
  removeTotalField(column: string) {
    // Makes field.total = false
    const fieldMap = typedStore.activeVisualisation.visualisationStore.app.config.fieldMap

    // Set fieldMap total to false
    const field = fieldMap.find((f) => f.column === column)
    if (!field) {
      throw new Error(`Field with column "${column}" not found`)
    }
    field.total = false
  }

  get piechartItems() {
    return typedStore.activeVisualisation.visualisationStore.app.config.piechartItems.filter(
      (piechartItem) =>
        typedStore.activeVisualisation.visualisationStore.app.config.fieldMap.some(
          (field) => field.column === piechartItem.column,
        ),
    )
  }

  @Mutation
  setPiechartItems(piechartItems: PiechartItem[]) {
    typedStore.activeVisualisation.visualisationStore.app.config.piechartItems = piechartItems
  }

  @Action({ rawError: true })
  addPiechartItem(column: string) {
    const fieldMap = typedStore.activeVisualisation.visualisationStore.app.config.fieldMap
    const field = fieldMap.find((f) => f.column === column)
    if (!field) {
      throw new Error(`Field with column "${column}" not found`)
    }

    // Add to piechartItems
    const existingPiechartItems = this.piechartItems
    // Check it isn't already present
    if (existingPiechartItems.find((f) => f.column === column)) return
    // Else add the column
    const index = fieldMap.indexOf(field)
    this.setPiechartItems([
      ...existingPiechartItems,
      {
        color: PIE_COLORS[index % PIE_COLORS.length],
        column,
      },
    ])
  }

  @Action({ rawError: true })
  removePiechartItem(column: string) {
    // Remove from piechartItems
    const existingPiechartItems = this.piechartItems
    this.setPiechartItems([
      ...existingPiechartItems.filter((item) => {
        return item.column !== column
      }),
    ])
  }

  get fieldMap(): (FieldMapItem | FieldCalculationV2Config)[] {
    return [
      ...typedStore.activeVisualisation.visualisationStore.app.config.fieldMap,
      ...typedStore.activeVisualisation.visualisationStore.app.config.fieldCalculationsV2Format,
    ]
  }

  get field() {
    return (column: string) => {
      return this.fieldMap.find((item) => item.column === column)
    }
  }

  get numericMonetaryCalculationFields() {
    return [...this.numericFields, ...this.monetaryFields, ...this.calculationFields]
  }

  get numericMonetaryFields() {
    return [...this.numericFields, ...this.monetaryFields]
  }

  get numericFields() {
    return this.fieldMap.filter(isFieldNumeric)
  }

  get monetaryFields() {
    return this.fieldMap.filter(isFieldMonetary)
  }

  get calculationFields() {
    return this.fieldMap.filter(isFieldCalculation)
  }

  get stringFields() {
    return this.fieldMap.filter(isFieldString)
  }

  get totalFields() {
    return this.fieldMap.filter((field) => 'total' in field && field.total)
  }

  get name() {
    return (column: string) => {
      const field = this.field(column)
      return field && field.name ? field.name : column
    }
  }

  get monetaryFormatting() {
    return (value: number) => {
      // Given a numeric value, return a formatted currency string
      const monetaryFormatting =
        typedStore.activeVisualisation.visualisationStore.app.config.monetaryFormatting
      const numericFormatting =
        typedStore.activeVisualisation.visualisationStore.app.config.numericFormatting
      return value.toLocaleString(numericFormatting.locale, {
        style: 'currency',
        currency: monetaryFormatting.currency,
        maximumFractionDigits: Number(monetaryFormatting.numDigits),
        minimumFractionDigits: Number(monetaryFormatting.numDigits),
      })
    }
  }

  get numericFormatting() {
    return (value: number, options: Intl.NumberFormatOptions = {}) => {
      // Given a numeric value, return a formatted numeric string
      const numericFormatting =
        typedStore.activeVisualisation.visualisationStore.app.config.numericFormatting
      return value.toLocaleString(numericFormatting.locale, {
        minimumFractionDigits: Number(numericFormatting.numDigits),
        maximumFractionDigits: Number(numericFormatting.numDigits),
        ...options,
      })
    }
  }

  get showMap() {
    return typedStore.activeVisualisation.visualisationStore.app.config?.showMap ?? true
  }

  // #endregion config

  // #region titleDescriptionGetters

  get mapOrProjectName() {
    return typedStore.activeVisualisation.isMap ? this.mapName : this.projectName
  }

  get mapName() {
    return useNavButtonsStore().allBtns[NavButtonKeys.MAP].label
  }

  get projectName() {
    return useNavButtonsStore().allBtns[NavButtonKeys.BUBBLES].label
  }

  // #endregion titleDescriptionGetters

  // #region mention
  get mentionContent() {
    return (id: string) => {
      if (id === AUTO_TOTAL_FIELD_COLUMN) {
        const path = typedStore.activeVisualisation.storyPath
        return typedStore.visualisationApp.totalString({ path })
      }

      const field = typedStore.visualisationApp.field(id)

      if (!field) {
        return id
      }

      const { type } = field
      if (type === FieldTypes.STRING) {
        return this.mentionContentString(id)
      }

      return typedStore.visualisationData.formatValueByColumn(id) || `@${id}`
    }
  }

  // This is similar to data's getters: fieldLeafData, fieldLeafDataUnique but it takes no filter arguments to filter leafData
  get uniqueStrings() {
    return (id: string) => {
      const uniqueStrings = new Set(
        typedStore.visualisationData
          .leafData(typedStore.activeVisualisation.storyPath)
          .map((leafData) => leafData.data![id]),
      )
      return Array.from(uniqueStrings)
    }
  }

  get mentionContentString() {
    return (id: string) => {
      return this.uniqueStrings(id).join(', ')
    }
  }

  // #endregion mention

  // #region XY Bubbles Config

  get xyBubblesConfig(): XYBubblesConfig {
    return (
      typedStore.activeVisualisation.visualisationStore.app.config.xyBubblesConfig ??
      DEFAULT_XY_BUBBLES_CONFIG
    )
  }

  get xyDimensionsMappings() {
    return this.xyBubblesConfig.dimensionsMappings
  }

  @Mutation
  setXYDimensionsMappings(items: XYDimensionsMapping[]) {
    typedStore.activeVisualisation.visualisationStore.app.config.xyBubblesConfig.dimensionsMappings =
      items
  }

  @Action({ rawError: true })
  addXYDimensionsMapping(payload: XYDimensionsMapping) {
    const existingItems = this.xyDimensionsMappings
    if (existingItems.find((f) => f.xAxisTickKey === payload.xAxisTickKey)) return
    this.setXYDimensionsMappings([...existingItems, payload])
  }

  @Action({ rawError: true })
  removeXYDimensionsMapping(payload: XYDimensionsMapping) {
    const newMappings = this.xyDimensionsMappings.filter((mapping) => {
      return mapping.xAxisTickKey !== payload.xAxisTickKey
    })
    this.setXYDimensionsMappings(newMappings)
  }

  @Action({ rawError: true })
  updateXYDimensionsMapping(payload: XYDimensionsMapping) {
    const index = this.xyDimensionsMappings.findIndex(
      (f) => f.xAxisTickKey === payload.xAxisTickKey,
    )

    if (index !== -1) {
      const newMappings = [...this.xyDimensionsMappings]
      newMappings.splice(index, 1, payload)
      this.setXYDimensionsMappings(newMappings)
    }
  }

  // #endregion XY Column Pairs
}

function findDataNodeStory(nodes: NodeResponse[], path: Path, panel: NodeStoryKeys): string {
  const node = nodes.find((n) => n.path === path.toString())
  const nodeStory = node?.[panel]
  // If node has story, return node's story
  if (nodeStory && nodeStory !== '<p></p>') {
    return nodeStory
  }

  // If node does not have story, return the default group level's story
  const defaultStoryPath = `${DEFAULT_STORY_KEY}${path.level}`
  const defaultNode = nodes.find((n) => n.path === defaultStoryPath)
  const defaultStory = defaultNode?.[panel]
  if (defaultStory && defaultStory !== '<p></p>') {
    return defaultStory
  }

  // If there's no default group level's story, go up a node and the parent's story
  if (node?.parent) {
    return findDataNodeStory(nodes, new Path({ path: node.parent }), panel)
  }

  // If there's no story in node/default group's level/node's parent, or its parent's parent... etc
  // return root node's story
  const rootNode = nodes.find((n) => n.path === PATH_ROOT)
  return rootNode?.[panel] || ''
}

// check sumFieldsByUnit and sumTotalsByUnit
function sumTotalsByUnitItem([unit, total]: [string, number]) {
  return {
    type: FieldTypes.NUMERIC,
    unit,
    total,
  }
}

export const VisualisationAppModule = getModule(VisualisationApp)
