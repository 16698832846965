import { postConfig } from '@/api'
import {
  APP_CONFIG_KEY,
  BUBBLES_CLUSTERS_SORTING_CONFIG_KEY,
  BUBBLES_LEGENDS_CONFIG_KEY,
  BUBBLE_PARAMS_CONFIG_KEY,
  COMPARISON_GRAPH_CONFIG_KEY,
  CUSTOM_BUBBLES_GROUPS_CONFIG_KEY,
  DOWNLOAD_CONFIG_KEY,
  FAQ_CONFIG_KEY,
  FEEDBACK_CONFIG_KEY,
  FIELD_CALCULATIONS_NEW_CONFIG_KEY,
  FIELD_MAP_CONFIG_KEY,
  FILTER_BY_CATEGORY_KEY,
  GEOM_CONFIG_KEY,
  HALO_BUBBLES_CONFIG_KEY,
  HOLLOW_BUBBLES_CONFIG_KEY,
  MAP_CONFIG_KEY,
  MAP_POPUP_TOTAL_ITEMS_CONFIG_KEY,
  MONETARY_FORMATTING_CONFIG_KEY,
  NAV_BUTTONS_CONFIG_KEY,
  NUMERIC_FORMATTING_CONFIG_KEY,
  PIECHART_ITEMS_CONFIG_KEY,
  POPUP_CONFIG_KEY,
  POPUP_ITEMS_CONFIG_KEY,
  SEARCH_CONFIG_KEY,
  SHOW_MAP_CONFIG_KEY,
  SPLASH_SCREEN_CONFIG_KEY,
  STORY_SETTINGS_CONFIG_KEY,
  SUMMARY_ITEMS_CONFIG_KEY,
  SUMMARY_TABLE_CONFIG_KEY,
  VISUALISATION_CONFIG_KEY,
  XY_BUBBLES_CONFIG_KEY,
} from '@/constants'
import { VersionConfig } from '@/types'

class ConfigService {
  async updateVersionConfig(config: VersionConfig) {
    config.showMap = config.showMap ?? true

    const baseConfigKeys = [
      APP_CONFIG_KEY,
      BUBBLE_PARAMS_CONFIG_KEY,
      BUBBLES_LEGENDS_CONFIG_KEY,
      BUBBLES_CLUSTERS_SORTING_CONFIG_KEY,
      COMPARISON_GRAPH_CONFIG_KEY,
      CUSTOM_BUBBLES_GROUPS_CONFIG_KEY,
      DOWNLOAD_CONFIG_KEY,
      FAQ_CONFIG_KEY,
      FEEDBACK_CONFIG_KEY,
      FIELD_CALCULATIONS_NEW_CONFIG_KEY,
      FIELD_MAP_CONFIG_KEY,
      FILTER_BY_CATEGORY_KEY,
      HALO_BUBBLES_CONFIG_KEY,
      HOLLOW_BUBBLES_CONFIG_KEY,
      MONETARY_FORMATTING_CONFIG_KEY,
      NAV_BUTTONS_CONFIG_KEY,
      NUMERIC_FORMATTING_CONFIG_KEY,
      PIECHART_ITEMS_CONFIG_KEY,
      POPUP_CONFIG_KEY,
      POPUP_ITEMS_CONFIG_KEY,
      SEARCH_CONFIG_KEY,
      SPLASH_SCREEN_CONFIG_KEY,
      STORY_SETTINGS_CONFIG_KEY,
      SUMMARY_ITEMS_CONFIG_KEY,
      SUMMARY_TABLE_CONFIG_KEY,
      VISUALISATION_CONFIG_KEY,
      SHOW_MAP_CONFIG_KEY,
      XY_BUBBLES_CONFIG_KEY,
    ]

    const mapConfigKeys = config.showMap
      ? [MAP_CONFIG_KEY, GEOM_CONFIG_KEY, MAP_POPUP_TOTAL_ITEMS_CONFIG_KEY]
      : []

    const configKeys = [...baseConfigKeys, ...mapConfigKeys]
    return await Promise.all(
      configKeys.map((key) => {
        return postConfig({
          key: key as keyof VersionConfig,
          value: config[key as keyof VersionConfig] ?? {},
        })
      }),
    )
  }
}

export const configService = new ConfigService()
