import { authPost, awaitRequestJson } from '@/api/helpers'
import { recaptchaService } from '@/services'
import { AppRank, Feedback, PostCommentRequest, PostRatingRequest, RatingResponse } from '@/types'

export async function postRating(appRank: AppRank, payload: PostRatingRequest) {
  const recaptchaToken = await recaptchaService.execute('feedback')
  return awaitRequestJson<RatingResponse[]>(
    authPost({
      path: 'feedback/rating',
      body: {
        feedback_question_id: payload.questionId,
        user_session_id: payload.sessionId,
        path: payload.path,
        friendly_path: payload.friendlyPath,
        rating: payload.rating,
        recaptcha_token: recaptchaToken,
      },
      appRank,
    }),
  )
}

export async function postComment(appRank: AppRank, payload: PostCommentRequest) {
  const recaptchaToken = await recaptchaService.execute('feedback')
  return authPost({
    path: 'feedback/comment',
    body: {
      feedback_question_id: payload.questionId,
      user_session_id: payload.sessionId,
      path: payload.path,
      friendly_path: payload.friendlyPath,
      comment: payload.comment,
      recaptcha_token: recaptchaToken,
    },
    appRank,
  })
}

export async function getFeedback(appRank: AppRank, sessionId: string) {
  return awaitRequestJson<Feedback[]>(
    authPost({
      path: 'feedback/by-session-id',
      body: {
        user_session_id: sessionId,
      },
      appRank,
    }),
  )
}
