/* eslint-disable no-useless-constructor */
import { is } from '@/helpers'
import { Path } from '@/types'

const PATH_ROOT = 'root'
const PATH_SUBROOT = 'subroot'
const PATH_SEPARATOR = '/'

// TODO redefining this here prevents circular deps which were breaking the tests. Fix those deps.
export function isRootOrSubroot(path: string) {
  return path === PATH_ROOT || path === PATH_SUBROOT
}

// aka a Row, this is the aggregate of all Datas that share a recordId
// Previously all visualisations used something equivalent to this
// But the bubble aggregation feature changes that
export interface LeafNode {
  name: string
  path: Path
  data: Record<string, string>
  recordId: number
}

export interface TreeNodeResponse {
  name: string
  path: string
  children?: TreeNodeResponse[]
  data?: Record<string, string>
  recordId?: number
}
// Matches the backend response dto. Only exists in tree form, converted to other types when flattened
// If a leaf node, children is null, data is non-null and recordId is non-null
// Otherwise the inverse is true
export class TreeNode {
  name: string
  path: Path
  // eslint-disable-next-line no-use-before-define
  children?: TreeNode[]
  data?: Record<string, string>
  recordId?: number

  constructor(node: {
    name: string
    path: string | Path
    children?: (TreeNode | TreeNodeResponse)[]
    data?: Record<string, string>
    recordId?: number
  }) {
    this.name = node.name
    this.path = node.path instanceof Path ? node.path : new Path({ path: node.path })
    this.children = node.children?.map((c) => new TreeNode(c))
    this.data = node.data
    this.recordId = node.recordId
  }

  get level(): number {
    return this.path.level
  }

  get pathParts(): string[] {
    return this.path.parts
  }

  get isLeafNode(): boolean {
    return !this.children
  }

  // To be called after checking !children, should be a debug assert
  get asLeafNode(): LeafNode {
    return {
      name: this.name,
      path: this.path,
      data: this.data!,
      recordId: this.recordId!,
    }
  }

  traverse(withNode: (node: TreeNode) => boolean) {
    if (withNode(this)) {
      for (const child of this.children || []) {
        child.traverse(withNode)
      }
    }
  }

  filterLeafNodes(filter: (node: LeafNode) => boolean): TreeNode | null {
    if (this.isLeafNode) {
      return filter(this.asLeafNode) ? this : null
    }

    const children = this.children!.map((c) => c.filterLeafNodes(filter)).filter(is<TreeNode>())
    return children.length === 0
      ? null
      : new TreeNode({
          ...this,
          children,
        })
  }

  // May be called with root or subroot
  search(path: string | Path): TreeNode | false {
    const searchPath = path instanceof Path ? path : new Path({ path })
    if (this.path.isRoot) {
      if (searchPath.isRootOrSubroot) {
        return this
      }
      return this.children?.map((c) => this.searchNode(c, searchPath)).find((n) => n) ?? false
    }
    return this.searchNode(this, searchPath)
  }

  private searchNode(node: TreeNode, searchPath: Path): TreeNode | false {
    if (node.name !== searchPath.level1) return false
    if (searchPath.level === 1) return node
    for (const child of node.children || []) {
      const result = this.searchNode(child, new Path({ parts: searchPath.parts.slice(1) }))
      if (result) {
        return result
      }
    }
    return false
  }

  siblings(path: string): TreeNode[] {
    const parent = this.search(path.split(PATH_SEPARATOR).slice(0, -1).join(PATH_SEPARATOR))
    return (parent && parent.children?.filter((c) => c.path.toString() !== path)) || []
  }
}

// A node that is somewhat ready to be drawn
// It either represents a leaf node with its own data
// or a non-leaf node with data = the aggregate of all its descendants
// either way it needs neither a recordId nor children
export class VisNode {
  constructor(
    public name: string,
    public path: Path,
    public data: Record<string, string>,
    public total: number,
    public colour: string | null,
    public totalString: string,
    public scaledTotal: number,
    public isTranslucent: boolean,
  ) {}
}

export interface VisTreeNode extends VisNode {
  // eslint-disable-next-line no-use-before-define
  children?: VisTreeNode[]
  value?: number
  level: number
}

export class NodeGroup {
  constructor(
    public ancestorPath: string,
    public nodes: VisNode[],
  ) {}
}

export class NodeGroups {
  constructor(
    public side: VisNode[],
    public main: NodeGroup[],
  ) {}
}
